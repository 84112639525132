import ConfettiExplosion from 'react-confetti-explosion';

import { assert } from '../common/utility';

import useForceUpdate from "./forceUpdate.hook";
import useTimer from "./timer.hook";

var showConfetti = false;
var renderer = null;
var currentPopType = 'good';

const popProps = {
  'good':{
    particleCount:100, // default
    force:0.6,
    width:1000, // default
    colors:[
      '#FFC700',
      '#FF0000',
      '#2E3191',
      '#41BBC7'
    ], // default
  },
  'ok':{
    particleCount:60,
    force:0.4,
    width:700,
    colors:[
      '#FFC700',
      '#FFE38F',
      '#E38E73',
    ],
  },
  'bad':{
    particleCount:30,
    force:0.2,
    width:400,
    colors:[
      '#CCC',
      '#999',
      '#666',
      '#333'
    ],
  },
}

export default function useConfetti() {
  const forceUpdate = useForceUpdate();
  const timer = useTimer();
  
  function _setShowConfetti(newSC) {
    showConfetti = newSC;
    forceUpdate();
  }
  function _pop(popType) {
    currentPopType = popType;
    _setShowConfetti(true);
    timer.fn(() => {
      _setShowConfetti(false);
    }, 2500); // matched to docs listed time for explosion (https://www.npmjs.com/package/react-confetti-explosion)
  }
  function _render() {
    if(!showConfetti)
      return undefined;
    
    const popTypeProps = popProps[currentPopType];
    assert(!!popTypeProps);
    const defaultProps = {
      zIndex:3000,
    };
    const props = {...defaultProps, ...popTypeProps};
    
    return (
      <ConfettiExplosion {...props} />
    );
  }
  
  function popConfetti(popType) {
    assert(renderer);
    renderer.pop(popType);
  }
  function registerConfettiRenderer() {
    //assert(!renderer); // HACK - can't do this bc the renderer component will re-render and re-register
    renderer = {
      pop:_pop,
    };
    return _render;
  }

  return { popConfetti, registerConfettiRenderer };
}
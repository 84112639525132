import { useState } from "react";

import UserDataService from "../services/user.service";
import { capitalizeFirstLetter, objectEquals } from '../common/utility';

const dataFields = [
  'user',
  'lesson',
  'flashcards',
  'map',
  'progress',
  'dictionary',
  'journey',
];
var data = {};
export function resetAllData() {
  dataFields.forEach(field=>{
    data[field] = {
      data:null,
      loading:false,
      loadingObservers:[],
      changeObservers:{},
    };
  });
}
resetAllData();

async function loadData(field, options) {
  //console.log('loadData - begin', userId, field);
  data[field].loading = true;
  data.user.loading = true;
  var response;
  function trySetUpdatedAt() {
    if(response.result)
      response.result.updatedAt = response.user.updatedAt;
  }
  switch(field) {
  case 'user':
    response = await UserDataService.getUser();
    break;
  case 'progress':
    response = await UserDataService.getProgress();
    trySetUpdatedAt();
    break;
  case 'map':
    response = await UserDataService.getMap();
    trySetUpdatedAt();
    break;
  case 'dictionary':
    response = await UserDataService.getDictionary();
    trySetUpdatedAt();
    break;
  case 'journey':
    response = await UserDataService.getJourney();
    break;
  case 'lesson':
    response = await UserDataService.getLesson();
    break;
  case 'flashcards':
    response = await UserDataService.getFlashcards(options.wordOnly);
    break;
  default:
    alert(`ERROR - invalid field specified '${field}'`)
  }
  data[field].loading = false;
  data.user.loading = false;
  setDMData(field, response.result); // set data, call change observers
  setDMData('user', response.user);
  function processLoadingObservers(field) {
    data[field].loadingObservers.forEach(observerFn=>{
      observerFn(data[field].data);
    });
    data[field].loadingObservers = [];
  }
  processLoadingObservers(field);
  processLoadingObservers('user');
  //console.log('loadData - done', userId, field, response, data);
}

async function getData(field, options, forceLoad, cb) {
  //console.log('getData', userId, field, options, data[field]);
  if(data[field].loading) {
    data[field].loadingObservers.push((newData)=>{
      cb(newData);
    });
    return;
  }
  if(!forceLoad && !!data[field].data) {
    cb(data[field].data);
    return;
  }
  await loadData(field, options);
  //console.log('getData - loaded data', data)
  cb(data[field].data);
}

function setDMData(field, val) {
  data[field].data = val;
  
  //console.log('DM.setData calling COs', data[field].changeObservers);
  Object.values(data[field].changeObservers).forEach((coFunc)=>{
    coFunc(val);
  });
}
function addObserver(field, callerId, func) {
  data[field].changeObservers[callerId] = func;
}

function dataIsEqual(field, a, b) {
  if(!a || !b)
    return !a && !b;
  switch(field) {
  case 'user':
    return (a.updatedAt === b.updatedAt) && objectEquals(a.streakToday, b.streakToday);
  case 'progress':
  case 'map':
  case 'dictionary':
    return a.updatedAt === b.updatedAt;
  default:
    return true; // for flashcards, lesson, journey
  }
  //assert(false); // shouldn't get here
}

const DM = { getData, setData:setDMData, dataIsEqual, addObserver };

export default function useData(field, options=null) {
  const [data, setData] = useState(null);

  const hookInterface = {};

  if(!field)
    return hookInterface;

  // helpers
  function setDataCopy(newData) {
    const copy = structuredClone(newData);
    setData(copy); // this way each "useData" caller has their own copy & will know when the master copy changes
    return copy;
  }
  function setIfNotEqual(newData) {
    if(!DM.dataIsEqual(field, data, newData)) {
      //console.log('useData - set data', newData, options);
      setDataCopy(newData);
    }
    else {
      //console.log('useData - set data - is equal', options);
    }
  }
  
  // get initial data
  const isAuthed = !options || !('authed' in options) || options.authed;
  if(isAuthed) {
    DM.getData(field, options, false, result=>{
      if(result) {
        setIfNotEqual(result);
      }
      else { // server error or we're logged out
        var currentToken = localStorage.getItem('token');
        if(currentToken==='null')
          currentToken = null;
        console.log('LOGGED OUT. refresh?', currentToken);
        if(!!currentToken) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      }
    });
  }
  
  // track data changes
  const stack = (new Error()).stack;
  const callerId = stack.split('(')[2].split(')')[0];
  //console.log('useData for', caller);
  DM.addObserver(field, callerId, (newData)=>{
    //console.log('called CO for', field, callerId, newData);
    setIfNotEqual(newData);
  });

  // other funcs
  const forceReload = (cb=null, optionsOverride=null) => {
    DM.getData(field, (optionsOverride===null) ? options : optionsOverride, true, result=>{
      const newData = setDataCopy(result);
      if(cb)
        cb(newData);
    })
  };
  const setValue = (val) => {
    DM.setData(field, val);
    setDataCopy(val);
  };
  
  hookInterface[field] = data;
  hookInterface[`forceReload${capitalizeFirstLetter(field)}`] = forceReload;
  hookInterface[`set${capitalizeFirstLetter(field)}`] = setValue;
  
  return hookInterface;
}

import { useState, useEffect, useRef } from "react";
import { Toast } from 'bootstrap';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { getSLUTypeName, formatEnglish, getDictionaryPath, assert, arrayIntersperse, arrayDedupe, getStreakDay, disabledLinkStyles, showExtraTabs } from '../common/utility';
import UserDataService from "../services/user.service";

import ChinesePhrase from "./chineseWord.component";
import LessonCard from "./lessonCard.component";
import SubHeader from "./subHeader.component";
import Loading from "./loading.component";
import WordCard from "./wordCard.component";

import useData from "../hooks/data.hook";
import useVoice from "../hooks/voice.hook";
import useConfetti from "../hooks/confetti.hook";
import useForceUpdate from "../hooks/forceUpdate.hook";

const QUESTION_INDEX_NONE = 'none';
const QUESTION_INDEX_SUMMARY = 'summary';
const NULL_ANSWER = '';

// HACK: or is it? need this data not to be erased when user switches pages, can't put it in state, don't want it in localstorage, ...
function resetProgress(page) {
  allProgress[page] = {
    questionIndex:QUESTION_INDEX_NONE,
    answers:{},
  };
}
var allProgress = {};
resetProgress('lesson');
resetProgress('flashcards');
var loadingQuestions = false; // only used to avoid showing answer card while new questions are loading
var needFocusChange = null;
var needToastShow = false;

export default function Lesson(props) {
  const [ grammarsOn, setGrammarsOn ] = useLocalStorage("grammarsOn", false);
  const { user, setUser } = useData('user');
  const { lesson, forceReloadLesson } = useData('lesson');
  const { flashcards, forceReloadFlashcards } = useData('flashcards', { wordOnly:!grammarsOn });

  const [ answer, setAnswer ] = useState(NULL_ANSWER);
  const [ nextEnabled, setNextEnabled ] = useState(true);
  const toastsRef = useRef([]);
  const nextBtnRef = useRef();
  const inputBoxRef = useRef();
  const [toasts, setToasts] = useState([]);
  const voice = useVoice();
  const { popConfetti } = useConfetti();
  const forceUpdate = useForceUpdate();
  
  // handle focus
  useEffect(() => {
    if(!!needFocusChange) {
      switch(needFocusChange) {
      case 'input':
        if(!!inputBoxRef.current) {
          inputBoxRef.current.focus();
          needFocusChange = null;
        }
        break;
      case 'next':
        if(!!nextBtnRef.current) {
          nextBtnRef.current.focus();
          needFocusChange = null;
        }
        break;
      default:
        assert(false);
      }
    }
    if(needToastShow) {
      const bsToast = Toast.getOrCreateInstance(toastsRef.current[toasts.length-1]);
      assert(bsToast);
      bsToast.show();
      needToastShow = false;
    }
  });

  // get questions data
  const flashcardMode = !!props.flashcardMode;
  const page = flashcardMode ? 'flashcards' : 'lesson';
  var answers;
  function resetAnswers() {
    answers = allProgress[page].answers;
  }
  resetAnswers();
  const questionSet = flashcardMode ? flashcards : lesson;
  const questionIndex = allProgress[page].questionIndex;
  var question;

  // if still loading first questions set via useData calls
  function loadingScreen() {
    return (<Loading />);
  }
  if(!user || !questionSet) {
    return loadingScreen();
  }
  // flashcards - if we tried loading on the first lesson, we don't have any yet
  else if(!questionSet.questions.length) {
    getNewQuestions();
    return (<>No flashcards available yet! Try completing your first lesson</>);
  }
  // if we are on the summary
  else if(questionIndex === QUESTION_INDEX_SUMMARY) {
    question = {};
  }
  // if we have loaded the first question set but haven't set the question
  else if(questionIndex === QUESTION_INDEX_NONE) {
    finishQuestionsLoad(questionSet);
    return loadingScreen();
  }
  // we are on a regular question
  else {
    question = questionSet.questions[questionIndex];
  }
  
  // question & answer funcs
  function finishQuestionsLoad(newQS) {
    //console.log('Lesson - loaded questions', newQS);
    setCurrentQuestion(0);
  }
  function getNewQuestions(optionsOverride = null) {
    loadingQuestions = true; // set this in case it wasn't already set

    function reloadDone(newQS) {
      resetProgress(page);
      resetAnswers();
      loadingQuestions = false;
      finishQuestionsLoad(newQS);
    }
    if(flashcardMode) {
      forceReloadFlashcards(reloadDone, optionsOverride);
    }
    else {
      forceReloadLesson(reloadDone, optionsOverride);
    }
  }
  function newAnswer(text, correct=null) {
    return { text, correct };
  }
  function setQuestionIndex(newIndex) {
    allProgress[page].questionIndex = newIndex;
    forceUpdate();
  }
  function setCurrentQuestion(index) {
    //console.log('setCurrentQuestion', index, answers);
    setQuestionIndex(index);
    var answerText = '';
    const alreadyAnswered = index in answers;
    if(alreadyAnswered) {
      answerText = answers[index].text;
    }
    setAnswer(answerText);
    const nextQuestion = questionSet.questions[index];
    setNextEnabled(!nextQuestion.isQuiz || alreadyAnswered);
    if(nextQuestion.isQuiz)
      needFocusChange = 'input';
    else
      needFocusChange = 'next';

    if(!alreadyAnswered) {
      var voiceText, lang = 'chinese';
      switch(nextQuestion.type) {
        case 'word':
          voiceText = nextQuestion.data.characters;
          break;
        case 'expression':
          //console.log('expression', nextQuestion.data.chinese, questionSet.usedWordData);
          voiceText = nextQuestion.data.chinese.map(ew=>(ew.type==='word')?questionSet.usedWordData[ew.wordId]?.characters:` ${formatEnglish(ew.str, user)} `).join('');
          break;
        case 'chineseToEnglish':
          voiceText = nextQuestion.chinese.words.map(w=>w.characters).join('');
          break;
        case 'englishToChinese':
          voiceText = formatEnglish(nextQuestion.english.text, user);
          lang = 'english';
          break;
        default:
          // no action item, other question types aren't spoken
      }
      if(voiceText)
        voice.speak(voiceText, lang);
    }
  }
  function onChangeAnswer(e) {
    const answer = e.target.value;
    setAnswer(answer);
    setNextEnabled(!!answer);
  }
  function onKeyDown(e) {
    if(e.key === 'Enter' && nextEnabled && questionSet.questions[questionIndex]?.isQuiz) {
      onSubmitAnswer();
      e.preventDefault();
    }
  }
  function getScore(right, total) {
    return (total===0)?100:Math.round(100*right/total);
  }
  function scoreClass(score) {
    var name = 'bad';
    if(score>=90)
      name = 'good';
    else if(score>=80)
      name = 'ok';
    return name;
  }
  function scorePopType(scoreClass) {
    return scoreClass;
  }
  function scoreColorName(score) {
    const scoreColors = {
      'bad':'danger',
      'ok':'warning',
      'good':'success',
    };
    const color = scoreColors[scoreClass(score)];
    assert(!!color);
    return color;
  }
  function onSubmitAnswer() {
    // if on the summary card, advance to next lesson
    if(questionIndex === QUESTION_INDEX_SUMMARY) {
      getNewQuestions();
      return;
    }
    
    // if already answered (ie user had clicked back), advance to next question
    function advanceQuestion() {
      const nextQuestionIndex = questionIndex+1;
      const setDone = nextQuestionIndex===questionSet.questions.length;
      // for lessons
      if(!flashcardMode) {
        // if we're done w lesson
        if(setDone) {
          // submit answers
          loadingQuestions = true; // technically we're not loading questions yet but we don't want to show the answer card while we finish lesson & load next questions
          UserDataService.finishLesson({
            isFlashcards:false,
            questions:questionSet.questions.map((q, i)=>({
              isNewWord:q.isNewWord,
              wordResults:answers[i].wordResults,
              grammarId:q.grammarId,
            })),
          })
          .then(result => {
            //setUser(result.user); // setting this here will advance lesson # while we're on summary card. getting new questions should pull a new user too & set it then
            // if lesson mode
            if(!flashcardMode) {
              allProgress.summary = {
                total:0,
                correct:0,
              }
              questionSet.questions.forEach((q, index)=>{
                if(q.isQuiz) {
                  allProgress.summary.total++;
                  if(answers[index].correct)
                    allProgress.summary.correct++;
                }
              });
              const lessonRight = allProgress.summary.correct, lessonTotal = allProgress.summary.total;
              const lessonScore = getScore(lessonRight, lessonTotal);
              setQuestionIndex(QUESTION_INDEX_SUMMARY);
              popConfetti(scorePopType(scoreClass(lessonScore)));
              needFocusChange = 'next';
            }
            // if flashcard mode
            else {
              // load next flash cards
              getNewQuestions();
            }
          })
          .catch(e => {
            console.log(e);
          });
        }
        // if we're not done w lesson
        else {
          // display next question
          setCurrentQuestion(nextQuestionIndex);
        }
      }
      // for flashcards
      else {
        // async submit answer
        const question = questionSet.questions[questionIndex];
        const answer = answers[questionIndex];
        UserDataService.finishLesson({
          isFlashcards:true,
          questions:[{
            isNewWord:question.isNewWord,
            wordResults:answer.wordResults,
            grammarId:question.grammarId,
          }],
        })
        .then(result => {
          console.log('saved flashcard', result);
        })
        .catch(e => {
          console.log(e);
        });        
        // if we're done w set
        if(setDone) {
          // load next flash cards
          getNewQuestions();
        }
        // if we're not done w set
        else {
          // display next question
          setCurrentQuestion(nextQuestionIndex);
        }
      }
    }
    if(questionIndex in answers) {
      advanceQuestion();
      return;
    }
    
    // begin saving answer to question
    var answerText = (answer===NULL_ANSWER) ? 'newWord' : answer;
    const questionAnswer = newAnswer(answerText);
    answers[questionIndex] = questionAnswer;
    
    // handle question types
    var right = null, missedWords, wordResults;
    switch(question.type) {
    case 'englishToChinese':
    case 'chineseToEnglish':
      // determine what question answers we're looking for
      const questionWords = {};
      const langTo = (question.type==='englishToChinese') ? 'chinese' : 'english';
      var qws;
      if(langTo==='chinese') {
        qws = question.chinese.words;
      }
      else {
        const wordIds = Object.values(question.english.answerKey).map(ak=>ak.wordId);
        qws = question.chinese.words.filter(cw=>wordIds.includes(cw.wordId));
      }
      qws = arrayDedupe(qws, (a,b)=>a.wordId===b.wordId);
      qws.forEach(word=>{
        var lookFor = '';
        if(langTo === 'chinese') {
          var chineseWord;
          switch(user.stage) {
          case 0:
            chineseWord = word.toneless.replaceAll('·','');
            break;
          case 1:
            chineseWord = word.tones;
            break;
          case 2:
            chineseWord = word.characters;
            break;
          default:
            assert(false);
          }
          lookFor = chineseWord.toLowerCase();
        }
        else {
          // HACK - this doesn't check words in order, just treats them as a bag of words. better to go through the answer key in order and look for words, marking right or wrong as we go (although this has challenges)
          Object.values(question.english.answerKey).filter(ak=>ak.wordId===word.wordId).forEach(ak=>{
            var adjustedEnglish = ak.english ? formatEnglish(ak.english, user) : ''; // will be missing this for certain words like mws, particles
            lookFor += adjustedEnglish+' ';
            lookFor += ak.synonyms.join(' ')+' '; // HACK - compare against synonyms' lemma form verbatim
          });
          lookFor = lookFor.toLowerCase();
        }
        questionWords[word.wordId] = {
          id:word.wordId,
          word,
          lookFor,
          englishWord:Object.values(question.english.answerKey).find(ak=>ak.wordId===word.wordId)?.english,
          found:false,
        };
      });
      // check answer words to see if they're found
      if(langTo === 'english') {
        answerText = answerText.replaceAll(`'s`, ` 's`);
        answerText = answerText.replaceAll(`' `, ` 's `);
      }
      const answerWords = answerText.toLowerCase().split(' ');
      answerWords.forEach(wordStr=>{
        var foundQW = Object.values(questionWords).find(qw=>(!qw.found && qw.lookFor.includes(wordStr)));
        if(!!foundQW)
          foundQW.found = true;
      });
      // build answer correctness map
      var trailingDe = false;
      if(langTo === 'chinese') {
        trailingDe = question.correctAnswer.slice(-3)===' de' && question.correctAnswer.indexOf(' de ')===-1;
      }
      wordResults = {};
      var allCorrect = true;
      Object.keys(questionWords).forEach(wordId=>{
        var correct = questionWords[wordId].found;
        if(!correct && wordId==='的de5' && trailingDe)
          correct = true;
        wordResults[wordId] = correct;
        allCorrect = allCorrect && correct;
      });
      right = allCorrect;
      const wordsTotal = Object.keys(wordResults).length;
      const wordsCorrect = Object.values(wordResults).reduce((acc, correct)=>acc+(correct?1:0), 0);
      const allWords = Object.keys(wordResults);
      // account for situations where chinese & english are different and user gets english correct, implying they understand the missing chinese words, eg particles
      if(allCorrect) {
        Object.keys(wordResults).forEach(wordId=>{
          wordResults[wordId] = true;
        });
      }
      // update user streak (on client)
      const dayToday = getStreakDay();
      assert(user.streakToday);
      const streakToday = user.streakToday;
      const completeField = `${!flashcardMode?'lessonQuestion':'flashcard'}sComplete`;
      const correctField = `${!flashcardMode?'lessonQuestion':'flashcard'}sCorrect`;
      streakToday.questionWordsTotal += wordsTotal;
      streakToday.questionWordsCorrect += wordsCorrect;
      streakToday.uniqueWords = arrayDedupe([...(streakToday.uniqueWords??[]), ...allWords]);
      streakToday.uniqueGrammars = arrayDedupe([...(streakToday.uniqueGrammars??[]), question.grammarId]);
      streakToday[completeField]++;
      if(allCorrect) {
        streakToday[correctField]++;
      }
      var streakTodayFromHistory = user.streakHistory[dayToday];
      if(!streakTodayFromHistory) {
        streakTodayFromHistory = {
          day:dayToday,
        };
        user.streakHistory[dayToday] = streakTodayFromHistory;
      }
      streakTodayFromHistory.streak = streakToday;
      setUser(user);
      // determine missed words
      const missedQWs = Object.values(questionWords).filter(qw=>!wordResults[qw.id]);
      if(langTo==='chinese')
        missedWords = missedQWs.map(qw=>qw.englishWord);
      else
        missedWords = missedQWs.map(qw=>qw.id);
      break;
    default:
      // answering new word, grammar, etc questions is a no-op
    }
    
    // save answer to question
    if(question.isQuiz) {
      assert(!!wordResults && !!missedWords && right!==null);
      questionAnswer.correct = right;
      questionAnswer.missedWords = missedWords; // used to color words in answer card
      questionAnswer.wordResults = wordResults;
      addAnswerToast(right, answer, question.correctAnswer.trim());
    }
    //console.log('answers', answers);
    
    // advance
    if(!question.isQuiz || right) {
      advanceQuestion();      
    }
    else {
      needFocusChange = 'next';
      forceUpdate(); // to show wrong answer box & new toast
    }
  }
  function addAnswerToast(correct, answer, correctAnswer) {
    //console.log('addAnswerToast', correct, answer, correctAnswer);
    toasts.push({
      correct,
      answer,
      correctAnswer
    });
    var croppedToasts = toasts.slice(-10);
    setToasts(croppedToasts);
    needToastShow = true;
  }
  
  // UI funcs
  function onGoBack() {
    setCurrentQuestion(Math.max(0, questionIndex-1));
  }
  function onGrammarCheckChange(e) {
    const newGrammarOnVal = e.target.checked;
    setGrammarsOn(newGrammarOnVal);
    getNewQuestions({ wordOnly:!newGrammarOnVal });
  }
  function getGrammarPartNames(parts) {
    var names = [];
    parts.forEach(part=>{
      switch(part.type) {
      case 'grammar':
      case 'pos':
        const isGrammar = part.type==='grammar';
        const partName = isGrammar ? part.grammar.name : part.pos.name;
        names.push(partName);
        break;
      case 'operator':
        names = names.concat(getGrammarPartNames(part.parts));
        break;
      default:
        assert(false);
      }
    });
    return names;
  }
  function isItemNew(itemId) {
    const newItems = questionSet.questions.filter(q=>['word', 'grammar'].includes(q.type)).map(q=>(q.type==='word')?q.data.wordId:q.data.id); // HACK - should build this once after we load question set but it's not obvious where to stash this data, need to centralize things like progress, answers in one place
    return newItems.includes(itemId);
  }
  const newQuestionTypes = {
    'word':'Word',
    'grammar':'Grammar',
    'concept':'Concept',
    'pos':'Part of Speech',
    'expression':'Expression',
    'theme':'Topic',
  };

  // answer card
  const lessonDone = questionIndex === QUESTION_INDEX_SUMMARY;
  const isQuestionCard = question.type==='englishToChinese' || question.type==='chineseToEnglish';
  var showAnswer, questionEnglishJsx;
  if(isQuestionCard) {
    showAnswer = question.isQuiz && (questionIndex in answers) && !loadingQuestions;
    const questionEnglishStr = formatEnglish(question.english.text, user);
    if(!showAnswer) {
      questionEnglishJsx = questionEnglishStr;
    }
    else {
      var qeParts = [{
        right:true,
        text:questionEnglishStr,
      }];
      answers[questionIndex].missedWords.forEach(wordText=>{
        var newQEParts = [];
        qeParts.forEach(part=>{
          if(!part.right) {
            newQEParts.push(part); // don't check inside parts that are already wrong
          }
          else {
            var splitParts;
            if(part.text.startsWith(wordText+' ')) {
              splitParts = ['', part.text.slice(wordText.length+1)];
            }
            else if(part.text.endsWith(' '+wordText)) {
              splitParts = [part.text.slice(0,part.text.length-wordText.length-1), ''];
            }
            else {
              splitParts = part.text.split(' '+wordText+' ');
            }
            splitParts = arrayIntersperse(splitParts, ()=>null);
            newQEParts = newQEParts.concat(splitParts.map(splitPart=>({
              right:splitPart!==null,
              text:(splitPart===null)?wordText:splitPart,
            })));
          }    
        });
        qeParts = newQEParts;
      });
      questionEnglishJsx = qeParts.map(part=>(
        <span class={part.right?'':'text-danger'}>{part.text} </span>
      ));
    }
  }
  
  // lesson summary
  var chartData, chartOptions, learnedWords, wrongWords;
  if(lessonDone) {
    // chart data
    const documentStyle = getComputedStyle(document.body);
    function graphColor(score, isBorder=true) {
      return `rgba(${documentStyle.getPropertyValue('--bs-'+scoreColorName(score)+'-rgb')}, ${isBorder?1.0:0.5})`;
    }
    var weekRight = 0, weekTotal = 0;
    for(var i=0; i<7; ++i) {
      const day = getStreakDay(i);
      const streak = user.streakHistory.find(s=>s.day===day);
      if(!!streak.streak) {
        weekRight += streak.streak.questionWordsCorrect;
        weekTotal += streak.streak.questionWordsTotal;
      }
    }
    var dayRight = user.streakToday.questionWordsCorrect, dayTotal = user.streakToday.questionWordsTotal;
    var lessonRight = allProgress.summary.correct, lessonTotal = allProgress.summary.total;
    const scores = [
      getScore(lessonRight, lessonTotal),
      getScore(dayRight, dayTotal),
      getScore(weekRight, weekTotal),
    ];
    chartData = {
      labels:[
        'This lesson',
        'Today',
        'This week',
      ],
      datasets:[
        {
          axis:'y',
          data:scores,
          fill:false,
          backgroundColor:scores.map(s=>graphColor(s, false)),
          borderColor:scores.map(s=>graphColor(s)),
          borderWidth:2,
        },
      ],
    };
    function addPercent(value) {
      return value+'%';
    }
    function addPercentToContext(context) {
      return addPercent(context.formattedValue);
    }
    chartOptions = {
      indexAxis:'y',
      maintainAspectRatio:false,
      plugins:{
        legend:{
          display:false,
        },
        tooltip:{callbacks:{label:addPercentToContext}},
      },
      scales:{
        x:{
          ticks:{callback:addPercent},
          min:0,
          max:100,
        },
        y:{
          grid:{display:false},
          min:0,
          max:100,
        },
      },
    };

    function getWordForDisplay(word, akEntry) {
      if(!akEntry) {
        akEntry = {
          pos:word.defPOSs[0],
          english:word.defs[0]
        };
      }
      assert(akEntry);
      word.id = word.wordId; // need this for word cards, and really we should be using id anyway
      word.english = akEntry.english;
      word.pos = akEntry.pos;
      word.chinese = [word];
      return word;
    }
    learnedWords = lesson.questions.filter(q=>q.type==='word').map(q=>{
      const word = q.data;
      var firstAk;
      for(var i=0; i<lesson.questions.length && !firstAk; ++i) {
        firstAk = Object.values(lesson.questions[i].english?.answerKey ?? {}).find(ak=>ak.wordId===word.wordId);
      }
      return getWordForDisplay(word, firstAk);
    });
    wrongWords = [];
    lesson.questions.forEach((q, i)=>{
      const wordResults = answers[i].wordResults;
      Object.keys(wordResults ?? {}).filter(wordId=>!wordResults[wordId]).forEach(wordId=>{
        const word = q.chinese.words.find(w=>w.wordId===wordId);
        var ak = Object.values(q.english.answerKey).find(ak=>ak.wordId===wordId);
        wrongWords.push(getWordForDisplay(word, ak));
      });
    });
    wrongWords = arrayDedupe(wrongWords, (a,b)=>a.wordId===b.wordId);
  }
      
  // render
  return (
    <> 
    {/* question page header */}
    <SubHeader headerRef={props.headerRef}>
      <div class="row">
        { !flashcardMode ?
        <div class="col-12 d-flex">
          <div class="text-start fw-medium">
          Level {user.level+1}, Lesson {user.lesson+1}
          </div>
          <div class="text-center flex-grow-1 px-4">
            <div className="progress h-100" role="progressbar">
              <div className="progress-bar" style={{width:`${(100*(lessonDone ? 1 : (questionIndex+1)/questionSet.questions.length))}%`}}></div>
            </div>
          </div>
        </div>
        :
        <>
          <div class="col-4">
            <div class="form-check form-switch">
              <input class="form-check-input" style={{cursor:'pointer'}} type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={onGrammarCheckChange} checked={grammarsOn} />
              <label class="form-check-label" style={{cursor:'pointer'}} for="flexSwitchCheckDefault">Include Grammars</label>
            </div>
          </div>
        </>
        }
      </div>
    </SubHeader>
    <div class="p-5 bg-light rounded-3 mt-4" style={flashcardMode?{boxShadow:`8px 8px 0 0px white,
      10px 6px 0 0px #eee,
      6px 10px 0 0px #eee,
      10px 10px 0 0px #eee`, border:`2px solid #eee`}:{}}>
      {/* question types */}
      { (question.type in newQuestionTypes) &&
      <>
        { !(question.type==='concept' && question.data.id==='welcome') &&
        <h4 class="text-success mb-3">New {newQuestionTypes[question.type]}!</h4>
        }
        <div class="mb-5">
          <LessonCard type={question.type} itemData={question.data} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }
      { (question.type==='shards') &&
      <>
        <h4 class="text-success mb-3">Updated Grammar!</h4>
        <div class="mb-5">
          <LessonCard type="grammar" itemData={question.data} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }
      { (question.type==='slu') &&
      <>
        <h4 class="text-success mb-3">New {getSLUTypeName(question.sluType)}!</h4>
        <div class="mb-5">
          <LessonCard type={question.sluType} slu={questionSet[question.sluType]} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }

      { isQuestionCard &&
      <>
        {/* question prompt */}
        { (question.type==='englishToChinese') ?
        <>
        <h4 class="text-muted">Type in {questionSet.stage.name}:</h4>
        <h1 class="mt-2 fw-bold">
          { questionEnglishJsx }
        </h1>
        </>
        :
        <>
        <h4 class="text-muted">Type in English:</h4>
        <h1 class="mt-2 fw-bold">
          <ChinesePhrase words={question.chinese.words} showStats={true} markWrong={showAnswer?answers[questionIndex].missedWords:[]} />
        </h1>
        </>
        }
        {/* input box */}
        <input class="form-control mt-4" onChange={onChangeAnswer} value={answer} onKeyDown={onKeyDown} disabled={questionIndex in answers} ref={inputBoxRef} autoFocus/>
        {/* correct answer (shown if user goes back) */}
        { showAnswer &&
        <div class={`alert alert-${answers[questionIndex].correct?'success':'danger'} mt-4`} role="alert">
          <h4 class="alert-heading">You were {answers[questionIndex].correct?'correct':'incorrect'}</h4>
          {!answers[questionIndex].correct &&
          <p>The correct answer was: <strong>{question.type==='englishToChinese' ? question.chinese.words.map(word=>word.toneless).join(' ') : formatEnglish(question.english.text, user)}</strong></p>
          }
          <hr/>
          <p>The words in the question were:</p>
          <ul>
            {question.chinese.words.map((word, index)=>
            <li key={index}><Link to={getDictionaryPath('word', word.wordId)} style={!isItemNew(word.wordId)?{color:'inherit'}:disabledLinkStyles}><strong>{word.toneless}</strong></Link>: {formatEnglish(word.defs[word.defPOSs.indexOf(word.pos)], user, word.pos, word.isProper)}</li>
            )}
          </ul>
          { !!question.grammarForFE &&
          <>
          <hr/>
          <p>The <Link to={getDictionaryPath('grammar', question.grammarForFE.id)} style={!isItemNew(question.grammarForFE.id)?{color:'inherit'}:disabledLinkStyles}><strong style={{'text-transform':'capitalize'}}>{question.grammarForFE.name}</strong></Link> grammar was used, which may include:</p>
          <ul class="mb-1">
            {getGrammarPartNames(question.grammarForFE.parts).map((elt, index)=>
            <li key={index}>{elt}</li>
            )}
          </ul>
          </>
          }
        </div>   
        }
      </>
      }
      {/* lesson complete (part 1)*/}
      { lessonDone &&
      <>
        <div class="row">
          <div class="col-md-8">
            <h4 class="text-success mb-3">Lesson complete!</h4>
            <h1>Level {user.level+1}, Lesson {user.lesson+1}</h1>
            { (allProgress.summary.total>0) &&
            <div class="mt-3 text-muted fs-4 mb-4">You got <span class="text-success">{allProgress.summary.correct}</span> questions correct out of {allProgress.summary.total}</div>              
            }
          </div>
          <div class="col-md-4">
            <span class={`badge bg-${scoreColorName(getScore(allProgress.summary.correct,allProgress.summary.total))} float-end fs-1`}>{getScore(allProgress.summary.correct,allProgress.summary.total)}%</span>
          </div>
        </div>
        
        <div class="row mb-4"><div class="col-12">
          <div class="rounded-3 bg-white p-3" style={{height:250,position:'relative'}}>
            <Chart type="bar" data={chartData} options={chartOptions} />
          </div>
        </div></div>
      </>
      }
      {/* question page footer */}
      <div class="row mt-3 justify-content-end">
        <div class="col-3 text-end">
          {!lessonDone && !!questionIndex && 
          <button class="btn btn-secondary me-1" onClick={onGoBack}>Back</button>
          }
          <button class="btn btn-primary" disabled={!lessonDone && !nextEnabled} onClick={onSubmitAnswer} onKeyDown={onKeyDown} ref={nextBtnRef}>Next</button>
        </div>
      </div>
    </div>

    {/* lesson complete (part 2)*/}
    { lessonDone &&
    <div class="p-5 bg-light rounded-3 mt-4">
      { !!learnedWords.length && 
      <div class="row mb-4"><div class="col-12">
        <h3 class="mb-3 fw-light">Words you learned</h3>
        <div class="row">
          { learnedWords.map(word=>
          <WordCard {...{
            user, word,
            colSize:6,
            fontSize:2,
            linkDisabled:true,
          }} />
          ) }
        </div>
      </div></div>
      }
      
      { !!wrongWords.length &&
      <div class="row mb-4"><div class="col-12">
        <h3 class="mb-3 fw-light">Words you missed</h3>
        <div class="row">
          { wrongWords.map(word=>
          <WordCard {...{
            user, word,
            colSize:6,
            fontSize:2,
            linkDisabled:learnedWords.find(lw=>lw.id===word.id), // don't allow click on new words
          }} />
          ) }
        </div>
      </div></div>
      }
      
      <div class="row">
        <div class="col-12">
          <h3 class="mb-3 fw-light">{lesson.stage.name} progress</h3>
          <div class="row p-2">
            <div class="col bg-white rounded-3 px-4 py-3 pb-4">
              <div class="row">
                <div class="col fs-4 text-muted">
                  <span>Level {user.level+1} - {lesson.level.name}</span>
                  <span class="badge bg-success float-end mt-1">{Math.ceil(100*(user.lesson+1)/lesson.level.numLessons)}%</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div className="progress mt-3" role="progressbar">
                    <div className="progress-bar" style={{width:`${100*(user.lesson+1)/lesson.level.numLessons}%`}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>                
          <div class="mt-3 text-muted fs-4">
            <p>{lesson.level.descShort}</p>
            <p class="mb-0">This level has <strong>{lesson.level.numLessons} lessons</strong> including <strong>{lesson.level.totalWords} words</strong> and <strong>{lesson.level.totalGrammars} grammars</strong>.</p>
          </div>
          { showExtraTabs(user) &&
          <div class="row"><div class="col-4">
            <Link to="/journey"><button class="btn btn-outline-secondary w-100 mt-4">See progress</button></Link>
          </div></div>
          }
        </div>
      </div>
    </div>
    }
    {/* toasts container */}
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      { toasts.map((toast, index)=>(
      <div class={`toast align-items-center text-bg-${toast.correct?'success':'danger'} border-0`} role="alert" aria-live="assertive" aria-atomic="true" ref={el => toastsRef.current[index] = el} key={index}>
        <div class="d-flex">
          <div class="toast-body">
            '{toast.answer}' was {toast.correct ?
            <b>correct</b>
            :
            <><b>incorrect</b>, the answer was '{toast.correctAnswer}'</>
            }
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
      ))}
    </div>
    </>    
  );
}
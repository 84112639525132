import { useState, useRef } from "react";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import '@popperjs/core/dist/esm/popper.js'
//import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // custom boostrap css
import "bootstrap-icons/font/bootstrap-icons.css"
import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css';

import Landing from "./components/landing.component";
import About from "./components/about.component";
import Features from "./components/features.component";
import Pricing from "./components/pricing.component";
import Login from "./components/login.component";
import Account from "./components/account.component";
import Map from "./components/map.component";
import Progress from "./components/progress.component";
import Lesson from "./components/lesson.component";
import Feedback from "./components/feedback.component";
import Dictionary from "./components/dictionary.component";
import Journey from "./components/journey.component";

import useAuth from "./hooks/auth.hook";
import useData from "./hooks/data.hook";
import useVoice from "./hooks/voice.hook";
import useTimer from "./hooks/timer.hook";
import useConfetti from "./hooks/confetti.hook";

import { assert, unicodeSpaces, showExtraTabs } from './common/utility';
import ENV from "./common/config.env";

if(ENV!=='dev') {
  console.log = ()=>{};
}

function navLink({link, text, isNav, classes, activeClasses, currLocation}) {
  classes ??= '';
  if(currLocation?.startsWith(link) && (link==='/'?link===currLocation:true)) { // HACK - will break if links start w same letters
    classes = activeClasses ?? 'me-3 fw-semibold text-bg-light rounded-3 border active ';
  }
  if(!isNav)
    return (<Link to={link}><button class={`btn ${classes?classes:''}`}>{text}</button></Link>);
  else
    return (<li class="nav-item"><Link to={link} class={`nav-link ${classes?classes:''}`} target={link.startsWith('http')?'_blank':''}>{text}</Link></li>);
}

function Header({headerRef}) {
  const { authed } = useAuth();
  const { user } = useData('user', {page:'header', authed});
  const { progress } = useData('progress', {authed});
  const [ lastQuestionsToday, setLastQuestionsToday ] = useState(null);
  const [ lastLesson, setLastLesson ] = useState(null);
  const questionsTodayRef = useRef();
  const lessonRef = useRef();
  const location = useLocation();
  const voice = useVoice();
  const timer = useTimer();
  const { popConfetti, registerConfettiRenderer } = useConfetti();
  const currLocation = location.pathname;
  const renderConfetti = registerConfettiRenderer();
  
  // volume button
  var volumeBtnClass;
  switch(voice.getVolume()) {
    case 'mute':
      volumeBtnClass = 'bi-volume-mute';
      break;
    case 'low':
      volumeBtnClass = 'bi-volume-down';
      break;
    case 'high':
      volumeBtnClass = 'bi-volume-up';
      break;
    default:
      assert(false);
  }
  function onVolumeBtnClick() {
    voice.incVolume();
  }

  // stats box
  var streakDays, questionsToday, lesson, totalLessons, lessonProgressBarValue = 0;
  const onFlashcardsPage = currLocation==='/flashcards';
  if(user && user.streakHistory) { // HACK - user={} sometimes. no idea why? so we need an extra check to ensure the user obj is "real"
    lesson = user.lesson;
    const todayStreak = user.streakToday;
    questionsToday = todayStreak ? todayStreak.flashcardsComplete+todayStreak.lessonQuestionsComplete : 0;
    assert(!isNaN(questionsToday));
    for(var i=0; i<user.streakHistory.length; ++i) {
      const streak = user.streakHistory.at(-1-i);
      if(!streak?.streak)
        break;
    }
    streakDays = i;
    if(lesson !== lastLesson) {
      setLastLesson(lesson);
      if(lastLesson !== null) {
        popNumber(lessonRef);
      }
    }
    if(questionsToday !== lastQuestionsToday) {
      setLastQuestionsToday(questionsToday);
      if(lastQuestionsToday !== null) {
        var confettiPopType;
        if(onFlashcardsPage && !(questionsToday%20) && questionsToday>0) {
          confettiPopType = 'ok';
        }
        popNumber(questionsTodayRef, confettiPopType);
      }
    }
  }
  if(progress) {
    totalLessons = progress.levelTotalLessons;
    lessonProgressBarValue = 33+67*(lesson??0)/(totalLessons??1);
  }
  function popNumber(ref, confettiPopType) {
    if(!ref.current) return;
    ref.current.classList.add('pop');
    timer.fn(() => {
      ref.current.classList.remove('pop');
    }, 500);
    if(confettiPopType) {
      popConfetti(confettiPopType);
    }
  }
  function getNumberBadgeColor(num, goodOkLevels) {
    assert(goodOkLevels.length===2);
    const good = goodOkLevels[0];
    const ok = goodOkLevels[1];
    if(num>=good)
      return 'text-bg-success';
    else if(num>=ok)
      return 'text-bg-warning';
    return 'text-bg-danger';
  }

  // render
  //console.log('Header - render', userId, user);
  const classes = 'me-3 text-bg-dark fw-light rounded-3 border ';
  return (
    <div class="row justify-content-center sticky-top" style={{zIndex:2000}} ref={headerRef}><div class="col-md-11">
      <header class="d-flex flex-wrap justify-content-center p-3 text-bg-dark rounded-bottom-3 navbar navbar-expand">
        <Link to="/" class={`d-flex align-items-center mb-3 mb-md-0 text-bg-dark text-decoration-none ${authed?'':'me-auto'}`}>
          <i class={`bi bi-translate fs-2 ${authed?'me-4':''}`}></i>
          { !authed &&
          <span class="fs-4 ms-3 fw-medium">Easy Chinese</span>            
          }
        </Link>

        { currLocation!=='/login' && currLocation!=='/signup' &&
        <>
          <ul class={`nav navbar-nav ${authed?'me-auto':''}`}>
            {!authed ?
            <>
              {navLink({link:'/about', text:'About', isNav:true, currLocation, classes})}
              {navLink({link:'/features', text:'Features', isNav:true, currLocation, classes})}
              {navLink({link:'/pricing', text:'Pricing', isNav:true, currLocation, classes})}
            </>
            :
            <>
              {navLink({link:'/', text:'Learn', isNav:true, currLocation, classes})}
              { showExtraTabs(user) && 
              <>
                {navLink({link:'/flashcards', text:'Practice', isNav:true, currLocation, classes})}
                {navLink({link:'/dictionary', text:'Dictionary', isNav:true, currLocation, classes})}
                {navLink({link:'/journey', text:'Journey', isNav:true, currLocation, classes})}
                {navLink({link:'/progress', text:'Stats', isNav:true, currLocation, classes})}
              </>
              }
            </>
            }
          </ul>
          <ul class={`nav navbar-nav`}>
            { authed ?
            <>
              <li class="nav-item"><button class={`btn nav-link ${classes} px-2`} onClick={onVolumeBtnClick}><i class={`bi ${volumeBtnClass} btn-icon`}></i></button></li>
              <li class="nav-item position-relative" style={{width:217}} id="statBox">
                <div class="position-absolute w-100 bg-white text-bg-white border border-dark border-4 rounded-3 p-2" style={{marginTop:(-4)}}>
                  <div class="position-absolute top-50 start-50 translate-middle" style={{zIndex:3000}}>
                    { renderConfetti() }
                  </div>
                  <table class="table table-borderless mb-0 w-100 h-100">
                    <tbody>
                      <tr class="mb-0 h-100">
                        <td class="text-dark py-2 px-1 fw-medium pe-2">Lesson</td>
                        <td class="py-2 px-1 w-auto h-100">
                          <div class="progress position-relative w-100 h-100" role="progressbar" ref={lessonRef}>
                            <div class="progress-bar" style={{width:`${lessonProgressBarValue}%`}}><strong class="fs-6">{(lesson!==undefined)?(lesson+1):''}</strong></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-borderless mb-0 w-100">
                    <tbody>
                      <tr class="mb-0">
                        <td class="text-dark py-2 px-1 fw-medium pe-2">Streak days</td>
                        <td class="py-2 px-1"><span class={`badge ${getNumberBadgeColor(streakDays, [7,3])} fs-6 float-end`} style={{marginTop:-1}}>{streakDays ?? unicodeSpaces(2)}</span></td>
                      </tr>
                      <tr class="mb-0">
                        <td class="text-dark py-2 px-1 fw-medium pe-2 pt-1">Questions today</td>
                        <td class="py-2 px-1 pt-1"><span class={`badge ${getNumberBadgeColor(questionsToday?questionsToday:0, [100,40])} fs-6 float-end`} style={{marginTop:-1}} ref={questionsTodayRef}>{questionsToday ?? unicodeSpaces(2)}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </>
            :
            <>
              {navLink({link:'/login', text:'Login', isNav:false, currLocation, classes:'btn-outline-primary me-2 ms-4'})}
              {navLink({link:'/signup', text:'Sign up', isNav:false, currLocation, classes:'btn-primary signup-btn'})}
            </>
            }
          </ul>
        </>
        }
      </header>
    </div></div>
  );
}

function Footer() {
  const { authed } = useAuth();
  const location = useLocation();
  const currLocation = location.pathname;
  
  const classes = 'ms-3 text-muted rounded-3 border px-2 ';
  const activeClasses = 'ms-3 text-muted rounded-3 border px-2 active ';
  return (
    <div class="row justify-content-center mt-5"><div class="col-md-11">
      <footer class="d-flex flex-wrap justify-content-between align-items-center p-3 px-4 rounded-top-3 text-bg-light mt-4">
        <p class="col-md-4 mb-0 text-muted">&copy; 2024 Easy Chinese, Inc</p>

        <dev class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-auto text-decoration-none">
          <Link to="/" class="text-muted"><i class="bi bi-translate fs-2"></i></Link>
        </dev>          

        <ul class="nav col-md-4 justify-content-end">
          { !authed ?
          <>
            {navLink({link:'/about', text:'About', isNav:true, classes, activeClasses, currLocation})}
            {navLink({link:'/features', text:'Features', isNav:true, classes, activeClasses, currLocation})}
            {navLink({link:'/pricing', text:'Pricing', isNav:true, classes, activeClasses, currLocation})}
          </>
          :
          <>
            {navLink({link:'https://discord.com/invite/chineselanguage', text:'Community', isNav:true, activeClasses, classes})}
            {navLink({link:'/account', text:'Account', isNav:true, classes, activeClasses, currLocation})}
          </>
          }
        </ul>
      </footer>
    </div></div>
  );
}

function RequireAuth({children}) {
  const { authed } = useAuth();
  return authed === true ? children : <Navigate to="/login" replace />;
}

export default function App() {
  const { authed } = useAuth();
  const headerRef = useRef();

  return (
    <>
      <div>
        <Header headerRef={headerRef} />
        <div className="container mb-5">
          <div class="row justify-content-center">
            <div class="col-11">
              <Routes>
                <Route path="/" element={authed ? <Lesson key="lesson" headerRef={headerRef}/> : <Landing/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/features" element={<Features/>} />
                <Route path="/pricing" element={<Pricing/>} />
                <Route path="/login" element={<Login loginMode={true}/>} />
                <Route path="/signup" element={<Login loginMode={true}/>} />
                <Route path="/admin-signup" element={<Login/>} />
                <Route path="/progress" element={<RequireAuth><Progress headerRef={headerRef}/></RequireAuth>} />
                <Route path="/account" element={<RequireAuth><Account headerRef={headerRef}/></RequireAuth>} />
                <Route path="/journey" element={<RequireAuth><Journey headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId/:levelId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId/:levelId/:unitId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/flashcards" element={<RequireAuth><Lesson key="flashcards" flashcardMode={true} headerRef={headerRef}/></RequireAuth>} />
                <Route path="/dictionary" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />
                <Route path="/dictionary/:modeUrl" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />
                <Route path="/dictionary/:modeUrl/:id" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />
              </Routes>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      { authed && 
      <Feedback /> 
      }
    </>
  );
}


import { useNavigate } from "react-router-dom";

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";

export default function Account(props) {
  const { logout } = useAuth();
  const { user } = useData('user', {page:'account'});
  const navigate = useNavigate();
    
  //console.log('Account - render')
    
  function handleLogout() {
    logout().then((success) => {
      if(success) {
        navigate("/");
      }
    });
  }
  if(!user) {
    return (<Loading />);
  }
  return (
    <>
      <SubHeader headerRef={props.headerRef}>
      </SubHeader>
      <div class="p-5 bg-light rounded-3">
        <h1 class="mb-4">Account Settings</h1>
        <form>
          <div class="row mb-3">
            <label for="inputFName" class="col-sm-2 col-form-label">First name</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="inputFName" value={user?.fname} disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="email" class="form-control" id="inputEmail" value={user?.email} disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" id="inputPassword" disabled />
            </div>
          </div>
          <div class="row mt-2"><div class="col-md-2 offset-md-10">
            <button type="submit" class="btn btn-primary w-100" disabled>Update</button>
          </div></div>
        </form>
      </div>
      <div class="row mt-4"><div class="col-md-2">
        <button type="submit" class="btn btn-danger w-100" onClick={handleLogout}>Log out</button>
      </div></div>
    </>
  );
}
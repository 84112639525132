// links
export function getMapPath(stageIndex=null, levelIndex=null) {
  var path = '/map';
  if(stageIndex!==null) {
    path += `/stage-${stageIndex+1}`;
    if(levelIndex!==null) {
      path += `/level-${levelIndex+1}`;
    }
  }
  return path;
}
export function getScreenshotPath(screenshotName) {
  return `screenshots/${screenshotName}.png`;
}
export function getDictionaryPath(type, id) {
  return `/dictionary/${type}s/${id}`;
}

// arrays & objs
export function objectEquals(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function(isEqual, key) {
        return isEqual && objectEquals(x[key], y[key]);
      }, true) : (x === y);
}
export function arrayIntersperse(arr, getSeparator) {
  if(!arr?.length)
    return arr;
  const lastElt = arr.pop();
  const out = [];
  arr.forEach(elt=>{
    out.push(elt);
    out.push(getSeparator());
  });
  out.push(lastElt);
  return out;
}
export function arrayDedupe(arr, equalsFunc) {
  const out = [];
  if(!equalsFunc)
    equalsFunc = (a,b)=>a===b;
  arr.forEach(elt=>{
    if(out.find(outElt=>equalsFunc(outElt, elt))===undefined)
      out.push(elt);
  });
  return out;
}

// streaks
export function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function(num) {
        return (num < 10 ? '0' : '') + num;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
}
export function getStreakDay(daysAgo=0) {
  return toIsoString(new Date(Date.now() - daysAgo*24*60*60*1000)).slice(0,10);
}

// rendering
export function formatEnglish(text, user, pos=null, isProper=null) {
  text = text.replaceAll('{userFName}', user.fname);
  text = text.replaceAll('{userfname}', user.fname); // HACK - because the def gets lowercased somewhere upstream
  text = text.replaceAll('[', '(');
  text = text.replaceAll(']', ')');
  if(pos) {
    if(posIsVerb(pos) && pos!=='verb-helper') {
      text = '(to) '+text;
    }
    else if(isProper) {
      text = capitalizeFirstLetter(text);
    }
    else if(text==='i') {
      text = 'I';
    }
  }
  return text;
}
export function formatChinese(word, user) {
  assert(word && user);
  var text;
  switch(user.stage) {
  case 0:
    text = word.toneless;
    break;
  case 1:
    text = word.tones;
    break;
  case 2:
    text = word.characters;
    break;
  default:
  }
  assert(text);
  return text;
}
export function capitalizeFirstLetter(string) {
  if(!string)
    return '';
  if(string.slice(0,5)==='(to) ')
    return string.slice(0,5) + string.charAt(5).toUpperCase() + string.slice(6);
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function unicodeSpaces(num=1) {
  return '\u00A0'.repeat(num);
}
export const disabledLinkStyles = {
  pointerEvents:'none',
  textDecoration:'inherit',
  color:'inherit',
};
export function getSLUTypeName(type) {
  return capitalizeFirstLetter(type);
}
export function showExtraTabs(user) {
  return user && (user.stage>0 || user.level>0 || user.lesson>2);
}

// other
export function assert(isOk, msg) {
  if(!isOk)
    throw new Error(msg);
}
function posIsVerb(pos) {
  const verbs = [
    'verb',
    'verb-intrans',
    'verb-clause-object',
    'verb-helper',
    '是',
  ];
  return verbs.includes(pos);
}
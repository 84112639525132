import { Link } from "react-router-dom";
import { useRef, useEffect } from "react";

import useData from "../hooks/data.hook";

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";
import WordCard from "./wordCard.component";

import { getDictionaryPath, assert, disabledLinkStyles } from '../common/utility';

export default function Journey({headerRef}) {  
  const { user } = useData('user');
  const { journey } = useData('journey');
  const currentLessonMarker = useRef();

  useEffect(() => {
    const elt = currentLessonMarker.current;
    if(!elt)
      return;
    
    const y = elt.getBoundingClientRect().top + window.pageYOffset - window.innerHeight/2 + 100/2;
    window.scrollTo({top: y, behavior: 'smooth'});
  });

  // return if we haven't loaded data yet
  if(!user || !journey) {
    return (<Loading />);
  }

  // mark current lesson
  function lessonHasContent(lesson) {
    return !!lesson.themes.length || !!lesson.words.length || !!lesson.grammars.length;
  }
  function getLessonForSlu(slu) {
    return journey.stages[slu.stage].items[slu.level].items[slu.lesson];
  }
  var currentSlu = {
    stage:user.stage,
    level:user.level,
    lesson:user.lesson,
  }
  while(!lessonHasContent(getLessonForSlu(currentSlu))) {
    currentSlu.lesson++;
    if(!getLessonForSlu(currentSlu)) {
      currentSlu.level++;
      currentSlu.lesson=0;
      if(!getLessonForSlu(currentSlu)) {
        currentSlu.stage++;
        currentSlu.level=0;
        // otherwise user is done
      }
    }
  }
  const currentLesson = getLessonForSlu(currentSlu);
  assert(currentLesson);
  currentLesson.isCurrentLesson = true;
    
  // render
  console.log('Journey - render', journey);
  return (<>
    <SubHeader headerRef={headerRef}>
    </SubHeader>
    
    { journey.stages.map(stage=>(<>
      { stage.comingSoon ?
      <div class="alert alert-secondary my-4" role="alert">
        <strong>Stage {stage.id+1} - {stage.name}</strong> (not unlocked yet)
      </div>
      :
      <>
      <h1>Stage {stage.id+1} - {stage.name}</h1>
      { stage.items.map(level=>(<>
        { level.comingSoon ?
        <div class="alert alert-secondary my-4" role="alert">
          <strong>Level {level.id+1} - {level.name}</strong> (not unlocked yet)
        </div>
        : 
        <div class="p-5 bg-light rounded-3 mt-4">
          <h3 class="mb-3">Level {level.id+1} - {level.name}</h3>
          { level.items.map((lesson, lessonNum)=>(
          <>
          { lessonHasContent(lesson) ?
          <>
          { lesson.isCurrentLesson && 
          <div class="alert alert-success my-4 fs-3 text-center" role="alert" ref={currentLessonMarker}>
            <strong>Current lesson</strong>
          </div>
          }
          <div class="row">
            <div class="col-1 fs-3 text-muted fw-light text-center">
              <div class="row"><div class="col-12 p-2">
                <div class="pt-1">
                  {lessonNum+1}
                </div>
              </div></div>
            </div>
            <div class="col-11 px-0"><div class="row">
              { lesson.themes.map(theme=>(
              <div class="col-12 p-2"><div class={`${lesson.isComplete ? 'bg-white rounded-3' : ''} fw-semibold fs-4 px-3 py-2`}>
                { theme.emoji &&
                <span class="me-2">{theme.emoji}</span>
                }
                <span class="">Theme - {theme.name}</span>
              </div></div>
              )) }
              { lesson.grammars.map(grammar=>(
              <div class="col-12 p-2"><Link to={getDictionaryPath('grammar', grammar.id)} style={lesson.isComplete?{textDecoration:'inherit', color:'inherit'}:disabledLinkStyles}><div class={`${lesson.isComplete ? 'bg-white rounded-3' : ''} fs-4 px-3 py-2`}>
                <div class="fw-semibold">{grammar.name}</div>
                <div class="text-muted">{grammar.description}</div>
              </div></Link></div>
              )) }
              { lesson.words.map(word=>
              <WordCard {...{
                user, word,
                colSize:4,
                linkDisabled:!lesson.isComplete,
                whiteBox:lesson.isComplete,
                fontSize:4,
                includeChinese:false,
              }} />
              ) }              
            </div></div>
          </div>
          </>
          :
          <></>
          }
          </>
          )) }
        </div>
        }          
      </>)) }
      </>
      }
    </>)) }

  </>);
}
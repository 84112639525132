import { useEffect } from "react";
import { Link } from "react-router-dom";

import { getScreenshotPath } from '../common/utility';

export default function Features() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }); // run ever render

  const features = [
    {
      screenshotName:'dictionary-words',
      title:`2,000+ words`,
      desc:`Within just a few months you can reach conversational fluency using our handpicked vocabulary designed to cover any common situation`,
    },
    {
      screenshotName:'dictionary-grammars',
      title:`100+ grammars`,
      desc:`Includes the most common grammar structures in Chinese from a beginner to advanced level. Learn each grammar with unlimited examples and dynamically-generated quiz questions that use your learned vocabulary words`,
    },
    {
      screenshotName:'journey',
      title:`200+ lessons`,
      desc:`Learn 5 new words per lesson along with associated concepts and grammars. Each lesson includes personalized flashcards that quiz you on previously learned material you need to brush up on`,
    },
    {
      screenshotName:'journey-customize',
      title:`Personalized curriculum`,
      desc:`Choose your own adventure as you grow your vocabulary, prioritizing the topics that are most relevant to you`,
    },
    {
      screenshotName:'fluency',
      title:`Fluency tracking`,
      desc:`See your progress toward fluency, including detailed metrics on your weakest and strongest words, as you complete lessons and units and level up to more advanced vocabulary and grammars`,
    },
    {
      screenshotName:'click-chinese',
      title:`Listening practice`,
      desc:`Click to hear any word or phrase in Chinese. Practice your pronunciation by speaking along as you learn`,
    },
    {
      screenshotName:'type-chinese',
      title:`Free response questions`,
      desc:`Practice speaking and listening by translating English words, phrases, and sentences into Chinese, and vice versa. This style of learning simulates real dialogue more closely than multiple choice questions`,
    },
    {
      screenshotName:'flashcard',
      title:`Flashcards`,
      desc:`Quiz questions that drill you on the words and grammars you’re the weakest on. Choose “word only” flashcards to focus on vocabulary, or grammar flashcards to practice phrases and sentences`,
    },
    {
      screenshotName:'dictionary-filter',
      title:`Learner’s dictionary`,
      desc:`Personalized dictionary of words and grammars including only the ones you have learned. You can sort, filter, and group entries for easy access`,
    },
    {
      screenshotName:'streak',
      title:`Streaks`,
      desc:`Track your ongoing daily commitment and get inspired to keep learning`,
    },
    {
      screenshotName:'press-enter',
      title:`Keyboard shortcuts`,
      desc:`Quickly complete lesson after lesson without touching your mouse using our handy keyboard shortcuts`,
    },    
    {
      screenshotName:'discord',
      title:`Learner community`,
      desc:`Discuss the language and get help from other Chinese learners. Meet and make friends with others in your area`,
    },    
    {
      screenshotName:'dictionary-chinese',
      title:`Chinese characters`,
      desc:`Our core curriculum is focused on speaking & listening. When you're ready, learn to read & write Chinese characters as well`,
    },    
  ];
  features.forEach((feature, i)=>{
    const imgCol = (
    <div class="col-md-6 text-center">
      <img class="w-100 p-3 rounded-3 bg-white" src={getScreenshotPath(feature.screenshotName)} alt="" />
    </div>
    );
    const descCol = (
    <div class="col-md-6">
      <h3>{feature.title}</h3>
      <p class="mb-0 fs-5 text-muted">{feature.desc}</p>
    </div>
    );
    if(!(i%2)) {
      feature.leftCol = imgCol;
      feature.rightCol = descCol;
    }
    else {
      feature.leftCol = descCol;
      feature.rightCol = imgCol;
    }
  })
  return (
    <div class="mt-4">
      <div class="p-3 pb-md-4 mx-auto text-center">
        <h1 class="display-4 fw-normal mb-3">Features</h1>
      </div>
      {features.map(feature=>
      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid">
          <div class="row">
            {feature.leftCol}
            {feature.rightCol}
          </div>
        </div>
      </div>
      )}
      <Link to="/signup">
        <button className="btn btn-primary btn-lg mt-3 mb-5 signup-btn">Start now <i class="bi bi-chevron-right ms-1 fs-6"></i></button>
      </Link>
    </div>
  );
}
import { Link } from "react-router-dom";
import React from "react"; // need to include this to support the eval() below

import ChinesePhrase from "./chineseWord.component";
import WordCard from "./wordCard.component";

import useVoice from "../hooks/voice.hook";

import { capitalizeFirstLetter, getSLUTypeName, formatEnglish, formatChinese, getDictionaryPath, assert, arrayIntersperse, getScreenshotPath } from '../common/utility';

const useRef = React.useRef;
const useEffect = React.useEffect;
const useState = React.useState;

export default function LessonCard({type, itemData, slu, showSLUType, showSLUCurrent, fullView, usedWordData, user}) {
  const exampleTableContainer = useRef();
  const exampleTable = useRef();
  const [etShowScroll, setETShowScroll] = useState(false);

  const voice = useVoice();

  useEffect(() => {
    if(exampleTableContainer?.current && exampleTable?.current) {
      const showScroll = exampleTable.current.clientWidth > exampleTableContainer.current.clientWidth;
      if(showScroll !== etShowScroll) {
        setETShowScroll(showScroll);
      }
    }
  }); // run every time

  var cardJsx;

  function getPlayAudioFunc(wordIds) {
    return ()=>{
      const characters = wordIds.map(wordId=>usedWordData[wordId].characters).join('');
      if(characters) {
        voice.speak(characters);
      }
    };
  }

  function getGrammarVisual(grammar) {
    var ngvKey = 0;
    function getGrammarVisualInner(parts, operatorType='parens') {
      var out = [];
      function getOperator(type) {
        var text = '';
        var fs = 60;
        var marginTop = 0;
        switch(type) {
          case 'or':
            marginTop = 4;
            text = 'OR';
            fs = 24;
            break;
          case 'and':
            text = '+';
            fs = 50;
            break;
          case 'open':
            marginTop = -2;
            text = '(';
            break;
          case 'close':
            marginTop = -2;
            text = ')';
            break;
          default:
            assert(false);
        }
        return (
          <div class="position-relative grammar-operator" style={{'font-size':fs}} key={ngvKey++}>
            <span class="translate-middle position-absolute top-50 start-50" style={{marginTop}}>{text}</span>
          </div>
        );
      }
      parts.forEach(part=>{
        switch(part.type) {
        case 'grammar':
        case 'pos':
          const isGrammar = part.type==='grammar';
          const partName = isGrammar ? part.grammar.name : (part.pos.name + (!!part.tag ? ` (${capitalizeFirstLetter(part.tag)})` : ''));
          if(!partName)
            break;
          const partDesc = isGrammar ? part.grammar.description : part.pos.description;
          const isNew = grammar.shardIdsNew?.includes(part.topShardId);
          out.push(
            <div class="grammar-box" key={ngvKey++}>
              <div class={`card position-relative h-100 ${isNew?'text-bg-success':(!part.isOptional?'text-bg-dark':'')}`}>
                { isNew &&
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                  New!
                </span>
                }
                <div class="card-header">
                  <span>{partName}</span>
                  { isGrammar && part.grammar.isTaught && fullView && 
                  <Link to={getDictionaryPath('grammar', part.id)}>
                    <span class="float-end text-primary" style={{cursor:'pointer',transform:'scale(1.5)'}}><i class="bi bi-arrow-right-square-fill"></i></span>
                  </Link>
                  }
                </div>
                <ul class="list-group list-group-flush h-100">
                  <li class="list-group-item h-100">
                    {!!partDesc &&
                    <div class="mb-3">{partDesc}</div>
                    }
                  </li>
                </ul>
                {part.isOptional &&
                <div class="card-footer">
                  <span class="fst-italic">Optional</span>
                </div>
                }
              </div>
            </div>
          );
          break;
        case 'operator':
          var innerJsx = getGrammarVisualInner(part.parts, part.id);
          //console.log('finishing operator', part, innerJsx, operatorType)
          if(innerJsx.length>1 && parts.length>1 && part.id!==operatorType) { // if we aren't the only part on the level & we don't have the same operator as our parent
            innerJsx.unshift(getOperator('open'));
            innerJsx.push(getOperator('close'));
          }
          if(innerJsx.length)
            out.push(innerJsx);
          break;
        default:
          assert(false);
        }
      });
      switch(operatorType) {
        case 'or':
          out = arrayIntersperse(out, ()=>getOperator('or'));
          break;
        default:
          out = arrayIntersperse(out, ()=>getOperator('and'));
          break;
      }
      return out;
    }
    const out = getGrammarVisualInner(grammar.parts);
    return out;
  }
  function getProfVisual(item) {
    return (
      <div class="row mt-5">
        <div class="col-md-8 px-4">
          <div class="text-center bg-white rounded-3 p-1 h-100">
            <div class="fs-1 mt-2 fw-medium">{item.lastSeenStr}</div>
            <div class="text-muted mb-3">Last seen</div>
          </div>
        </div>
        <div class="col-md-4 px-4">
          <div class="text-center bg-white rounded-3 p-1 h-100">
            <div class="fs-1 mt-2 fw-medium">{item.lastRights} / {Math.min(3, item.seen)}</div>
            <div class="text-muted mb-3">Recent record</div>
          </div>
        </div>                  
        <div class="col-md-12 px-4 mt-4">
          <div class=" bg-white rounded-3 p-3 h-100">
            <div class="row">
              <div class="col fs-4 text-muted">
                <span>Accuracy</span>
                <span class="badge bg-success float-end">{Math.ceil(100/item.seen*item.correct)}%</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div className="progress mt-3 mb-2" role="progressbar">
                  <div className="progress-bar" style={{width:`${100/item.seen*item.correct}%`}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    );
  }
  
  // prepare context for description rendering functions
  window.React = React; // HACK - eval's scope is window, not this component, so we have to load the required context into window
  function reactChinesePhrase(wordIds, forceStage) {
    return <ChinesePhrase words={wordIds.map(wordId=>usedWordData[wordId])} showDefs={true} capitalize={true} forceStage={forceStage} />;
  }
  function getWordForCard(wordId, posId=null) {
    const word = usedWordData[wordId];
    var defIndex = 0;
    if(type==='pos') {
      assert(posId);
      defIndex = word.defPOSs.indexOf(posId); // which def has the new POS's id
    }
    word.english = word.defs[defIndex];
    word.pos = word.defPOSs[defIndex];
    word.chinese = [word];
    return word;
  }
  function reactWordCards(wordIds) {
    const wordCardsJsx = wordIds.map(wordId=>
      <WordCard {...{
        user, word:getWordForCard(wordId),
        colSize:12,
        linkDisabled:true,
      }} />
    );
    return (
      <div class="row w-50 mb-3">
        {wordCardsJsx}
      </div>
    );
  }
  function reactScreenshot(screenshotName) {
    return (
    <div class="bg-white rounded-3 p-3 w-50 my-4 mx-auto">
      <img class="w-100" src={getScreenshotPath(screenshotName)} alt="" />
    </div>
    );
  }
  window.reactChinesePhrase = reactChinesePhrase;
  window.reactWordCards = reactWordCards;
  window.reactScreenshot = reactScreenshot;
  
  // render
  switch(type) {
  case 'word':
    const word = itemData;
    const defs = [];
    word.defsFull?.forEach((def, index)=>{
      const defText = capitalizeFirstLetter(formatEnglish(def, user, word.defPOSs[index]));
      if(!defs.includes(defText))
        defs.push(defText);
    });
    cardJsx = (
    <>
      <h1>
        {formatChinese(word, user).trim()} 
        <i class="bi bi-play-circle-fill text-primary ms-2" style={{cursor:'pointer'}} onClick={getPlayAudioFunc([word.wordId])}></i>
      </h1>
      <div class="row">
        <div class={`col-${!!word.emoji?'9':'12'}`}>
          <div class="fst-italic">{word.poss.join(', ')}</div>
          { word.topic &&
            <div>Topic: {word.topic}</div>
          }
          <ol class="mt-3 fs-3">
            { defs.map((def, index) => (
              <li key={index}>{def}</li>
            ))}
          </ol>
        </div>
        { word.emoji &&
        <div class="col-2 position-relative">
          <span class={`position-absolute top-50 start-0 translate-middle-y mt-2`} style={{'font-size':'100px'}}>{word.emoji}</span>
        </div>              
        }
      </div>
      { !!word.seen && fullView &&
      <>{ getProfVisual(word) }</>
      }
    </>
    );
    break;
  case 'grammar':
    const grammar = itemData;
    cardJsx = (
    <>
      <h1>{grammar.name}</h1>
      <div class="mt-3 text-muted fs-4 mb-4">{grammar.descLong ?? grammar.description}</div>
      <div class="row mt-3">
        { getGrammarVisual(grammar) }
      </div>
      <div class="mt-3">
        <h4 class="mb-3 text-muted">A few examples:</h4>
        
        <div class="w-100 overflow-x-scroll" ref={exampleTableContainer}>
          <table class="table fs-5" ref={exampleTable}>
            <thead>
              <tr>
                <th scope="col"></th>
                { grammar.boxes.map(boxTitle=>
                <th scope="col"><span class="text-muted">{boxTitle}</span></th>
                ) }
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              { grammar.examples.map(example=>
              <tr>
                <td><i class="bi bi-play-circle-fill text-primary" style={{cursor:'pointer'}} onClick={getPlayAudioFunc(example.chineseWords.map(gw=>gw.wordId))}></i></td>
                { grammar.boxes.map((boxTitle, boxIndex)=>
                <td><strong>{reactChinesePhrase(example.chineseWords.filter(gw=>gw.boxIndex===boxIndex).map(gw=>gw.wordId))}</strong></td>
                ) }
                <td>{formatEnglish(example.english, user)}</td>
              </tr>
              ) }
            </tbody>
          </table>
        </div>
        { etShowScroll && 
        <div class="row fs-6 text-muted">
          <div class="col-2">
            <i class="bi bi-arrow-left"></i>
          </div>
          <div class="col-8 text-center">
            Scroll to see entire table
          </div>
          <div class="col-2">
            <i class="bi bi-arrow-right float-end"></i>
          </div>
        </div>
        }

      </div>
      { !!grammar.seen && fullView &&
      <>{ getProfVisual(grammar) }</>
      }
    </>
    );
    break;
  case 'concept':
    const concept = itemData;
    // eslint-disable-next-line
    const conceptDesc = eval(concept.descLong);
    cardJsx = (
    <>
      <h1 class={(concept.id==='welcome')?'text-success':''}>{formatEnglish(concept.name, user)}</h1>
      <div class="mt-3 text-muted lh-lg">{conceptDesc}</div>
    </>
    );
    break;
  case 'theme':
  case 'pos':
    const item = itemData;
    cardJsx = (
    <>
      <h1>{item.name}</h1>
      <div class="mt-3 text-muted fs-4 mb-4">{item.description}</div>
      { (item.displayWords.length>1) &&
      <h4 class="text-muted">Some words you&apos;ll learn:</h4>
      }
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            { item.displayWords.map(wordId=>(
              <WordCard {...{
                user, word:getWordForCard(wordId, item.id),
                colSize:6,
                linkDisabled:true,
              }} />
            )) }
          </div>
        </div>
        { /*item.emoji &&
        <div class="col-2">
          <div class="position-relative h-100">
            <span class="position-absolute top-50 start-50 translate-middle" style={{'font-size':'100px'}}>{item.emoji}</span>
          </div>              
        </div>              
        */ }
      </div>
    </>
    );
    break;
  case 'expression':
    const expression = itemData;
    var english = formatEnglish(expression.english, user);
    var englishJsx = [];
    while(english.length) {
      function splitOnce(str, splitter) {
        var i = str.indexOf(splitter);
        if(i===-1)
          return [str];
        else
          return [str.slice(0,i), str.slice(i+splitter.length)];
      }
      var parts = splitOnce(english, '('); // HACK - look for paren instead of bracket because formatEnglish replaces brackets w parens
      var str = parts[0];
      if(str.length) {
        englishJsx.push(<>{str}</>);
      }
      if(parts.length>1) {
        var parts2 = splitOnce(parts[1], ')');
        //assert(parts2.length==2);
        englishJsx.push(<span class='fw-medium'>[{parts2[0]}]</span>);
        english = parts2[1];
      }
      else {
        english='';
      }
    }
    const chinese = expression.chinese.map(ew=>{
      switch(ew.type) {
        case 'word':
          return usedWordData[ew.wordId];
        case 'rawText':
          return {
            rawText:formatEnglish(ew.str, user)
          };
        default:
          assert(false);
          return null;
      }
    });
    cardJsx = (
    <>
      <div class="row">
        <div class={`col-${expression.theme?.emoji?10:12}`}>
          <h4 class="text-muted">In English:</h4>
          <h1 class="mt-2 fw-bold">
            {englishJsx}
          </h1>
          <h4 class="text-muted mt-4">In Chinese:</h4>
          <h1 class="mt-2 fw-bold">
            <ChinesePhrase words={chinese} />
          </h1>
        </div>
        { expression.theme?.emoji &&
        <div class="col-2">
          <div class="position-relative h-100">
            <span class="position-absolute top-50 start-50 translate-middle" style={{'font-size':'100px'}}>{expression.theme.emoji}</span>
          </div>              
        </div>              
        }
      </div>
    </>
    );
    break;    
  case 'stage':
  case 'level':
    // eslint-disable-next-line
    const sluDesc = eval(slu.descLong);
    var sluIndexTitle = getSLUTypeName('stage')+' '+(user.stage+1);
    if(type==='level')
      sluIndexTitle += ', Level '+(user.level+1)
    cardJsx = (
    <>
      <p>
        <h1 class="mb-4">
          {sluIndexTitle + ' - '}
          {slu.name}
          { showSLUType && showSLUCurrent &&
          <span className={`badge text-bg-success float-end`} style={{marginTop:-2}}>Current {getSLUTypeName(type)}</span>
          }
        </h1>
      </p>
      <div class="mt-3 text-muted lh-lg">{sluDesc}</div>
      <div class="row mt-5">
        <div class="col-md-4 px-4">
          <div class="text-center bg-white rounded-3 p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.totalWords.toLocaleString()}</div>
            <div class="text-muted mb-3">Words</div>
          </div>
        </div>
        { !!slu.totalGrammars &&
        <div class="col-md-4 px-4">
          <div class="text-center bg-white rounded-3 p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.totalGrammars}</div>
            <div class="text-muted mb-3">Grammars</div>
          </div>
        </div>                  
        }
        { true &&
        <div class="col-md-4 px-4">
          <div class="text-center bg-white rounded-3 p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.numLessons}</div>
            <div class="text-muted mb-3">Lessons</div>
          </div>
        </div>                  
        }
      </div>
    </>
    );
    break;
  default:
    assert(false);
  }
    
  return cardJsx;
}

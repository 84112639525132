import { useEffect } from "react";
import { Chart } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

import { capitalizeFirstLetter, getDictionaryPath, assert, toIsoString } from '../common/utility';

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import useData from "../hooks/data.hook";

export default function Progress(props) {
  const { user } = useData('user');
  const { progress, forceReloadProgress } = useData('progress');
  
  const [ vocab, setVocab ] = useLocalStorage("vocab", 'days');
  const [ grammar, setGrammar ] = useLocalStorage("grammar", 'days');
  const [ sentence, setSentence ] = useLocalStorage("sentence", 'days');
  
  useEffect(() => {
    forceReloadProgress();
    // eslint-disable-next-line
  }, []); // run once when component is created

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }); // run ever render

  // graph helpers
  const documentStyle = getComputedStyle(document.body);
  const graphColor1 = documentStyle.getPropertyValue('--bs-warning');
  const graphColor2 = documentStyle.getPropertyValue('--bs-danger');
  const graphColor1Bg = `rgba(${documentStyle.getPropertyValue('--bs-warning-rgb')}, 0.5)`;
  const graphColor2Bg = `rgba(${documentStyle.getPropertyValue('--bs-danger-rgb')}, 0.5)`;
  function getGraphData(labels, data, unit='days', yAxisLabel=null, showLegend=false) {
    assert(labels.length===data.length);
    if(!labels.length) {
      labels = [toIsoString(new Date()).slice(0,10)];
      data = {};
      data[labels[0]] = 0;
    }
    const graph = {};
    // fill in missing labels, in case labels is sparse
    var fullLabels = [];
    var i=0;
    while(labels[0]!==fullLabels[0]) {
      const isoStr = toIsoString(new Date(new Date() - i*24*60*60*1000)).slice(0,10);
      fullLabels.unshift(isoStr);
      ++i;
    }
    var fullData = fullLabels.map(label=>{
      const index = labels.indexOf(label);
      if(index===-1)
        return 0;
      else
        return data[index];
    });
    // group data if unit != 'days'
    var unitPeriod = null;
    switch(unit) {
    case 'days':
      unitPeriod = 1;
      break;
    case 'weeks':
      unitPeriod = 7;
      break;
    case 'months':
      unitPeriod = 30;
      break;
    default:
      assert(false, `Invalid unit type passed to getGraphData`);
    }
    assert(unitPeriod!==null);
    if(unitPeriod!==1) {
      const adjustedLabels=[], adjustedData=[];
      var lastTotal = 0;
      function addAdjusted(label) {
        adjustedLabels.unshift(label);
        adjustedData.unshift(lastTotal);
      }
      unitPeriod-=1;
      var j; // i already declared above
      for(i=fullLabels.length-1, j=0; i>=0; --i, ++j) {
        lastTotal += fullData[i];
        if(j===unitPeriod) {
          addAdjusted(fullLabels[i]);
          j=0;
          lastTotal = 0;
        }
      }
      if(lastTotal) {
        addAdjusted(fullLabels[0]);
      }
      fullLabels = adjustedLabels;
      fullData = adjustedData;
    }
    // pad with zeros if we don't have enough data, crop if we have too much
    const maxLabels = 30;
    if(fullLabels.length<10) {
      var firstLabel;
      unitPeriod += 1;
      function labelSubtractDays(label, days) {
        return toIsoString(new Date(new Date(label)-(-5*60*60*1000)-(days*24*60*60*1000))).slice(0,10);
      }
      if(fullLabels.length===1)
        firstLabel = fullLabels[0];
      else {
        firstLabel = labelSubtractDays(fullLabels[1], unitPeriod);
        if(fullLabels[0]!==firstLabel)
          fullLabels[0] = firstLabel; // handle case where first label is a fractional step away from second
      }
      while(fullLabels.length<10) {
        firstLabel = labelSubtractDays(firstLabel, unitPeriod);
        fullLabels.unshift(firstLabel);
        fullData.unshift(0);
      }
    }
    else if(fullLabels.length>maxLabels) {
      fullLabels = fullLabels.slice(-maxLabels);
      fullData = fullData.slice(-maxLabels);
    }
    // create graph display data
    fullLabels = fullLabels.map(label=>label.slice(5));
    var axisTitle;
    if(yAxisLabel) {
      axisTitle = {
        display:true,
        text:yAxisLabel,
      };
    }
    graph.data = {
      labels:fullLabels,
      datasets:[
        {
          //label:'Flashcards',
          type:'line',
          data:fullData,
          fill:true,
          backgroundColor:graphColor1Bg,
          borderColor:graphColor1,
          tension:0.5,
          pointStyle:fullData.map(val=>val>0),
        },
      ],
    };
    graph.options = {
      maintainAspectRatio:false,
      plugins:{
        legend:{
          display:showLegend,
          labels:{padding:20},
        }
      },
      scales:{
        x:{
          grid:{display:false},
          ticks:{display:true}, //, maxTicksLimit:5},
        },
        y:{
          //stacked:true,
          title:axisTitle,
          ticks:{},
          grid:{display:true},
        },
      }
    };
    return graph;
  }

  // make streak graph
  const graphs = {};
  var studyDays=0, streakDays=0, heroOpeningStatement, heroClosingStatement, heroFlashcards=0, heroLessons=0;
  if(user) {
    var flashcardHistory = [], lessonHistory = [];
    user.streakHistory.forEach(streak=>{
      var flashcardVal=0, lessonVal=0;
      if(streak?.streak) {
        streak = streak.streak;
        flashcardVal = streak.flashcardsComplete;
        lessonVal = streak.lessonQuestionsComplete;
        heroFlashcards += flashcardVal;
        heroLessons += lessonVal;
        if(flashcardVal || lessonVal)
          studyDays++;
        streakDays++;
      }
      else {
        streakDays=0;
      }
      flashcardHistory.push(flashcardVal);
      lessonHistory.push(lessonVal);
    });
    if(studyDays>0) {
      const labels = user.streakHistory.map(streak=>streak.day);
      const flashcardsGraph = getGraphData(labels, flashcardHistory, 'days', 'Questions / day', true);
      const lessonsGraph = getGraphData(labels, lessonHistory, 'days', null, true);
      flashcardsGraph.data.datasets[0].label = 'Flashcards';
      lessonsGraph.data.datasets[0].label = 'Lesson questions';
      lessonsGraph.data.datasets[0].backgroundColor = graphColor2Bg;
      lessonsGraph.data.datasets[0].borderColor = graphColor2;
      flashcardsGraph.data.datasets.push(lessonsGraph.data.datasets[0]); // merge lesson data into flashcard obj for use below
      graphs.streak = {
        data:flashcardsGraph.data,
        options:flashcardsGraph.options
      };
    }
    const userJoinedDaysAgo = (Date.now()-Date.parse(user.createdAt))/1000/86400;
    if(studyDays<7) {
      heroOpeningStatement = `You must have been busy lately...`;
      heroClosingStatement = `Let's see if you can pick up the pace?`;
    }
    else if(studyDays<14) {
      heroOpeningStatement = `You're making good progress`;
      heroClosingStatement = `Keep up the great work.`;
    }
    else if(studyDays<21) {
      heroOpeningStatement = `You're a fantastic student`;
      heroClosingStatement = `Let's make learning Chinese a daily habit?`;
    }
    else {
      heroOpeningStatement = `Wow... you're dedicated!`;
      heroClosingStatement = `Before you know it, you'll be fluent.`;
    }
    if(userJoinedDaysAgo<30) {
      heroOpeningStatement = `You're just getting started!`;
    }
  }
  
  // make fluency & mastery graphs
  var allLevelInfo;
  if(progress) {
    const sortedStreakData = progress.streakData.sort((a,b)=>(new Date(a.day))-(new Date(b.day)));
    const labels = sortedStreakData.map(sd=>sd.day);
    function avoidNaN(num) {
      return (isNaN(num) || num===Infinity) ? 0 : num;
    }
    graphs.vocab = getGraphData(labels, sortedStreakData.map(sd=>sd.uniqueWords.length), vocab, `Unique words / ${vocab.slice(0,-1)}`);
    graphs.grammar = getGraphData(labels, sortedStreakData.map(sd=>sd.uniqueGrammars.length), grammar, `Unique grammars / ${grammar.slice(0,-1)}`);
    graphs.sentence = getGraphData(labels, sortedStreakData.map(sd=>avoidNaN(sd.questionWordsTotal/(sd.lessonQuestionsComplete+sd.flashcardsComplete))), sentence, 'Words');    

    graphs.wordCorrectness = getGraphData(labels, sortedStreakData.map(sd=>Math.floor(100*avoidNaN(sd.questionWordsCorrect/sd.questionWordsTotal))));
    graphs.grammarCorrectness = getGraphData(labels, sortedStreakData.map(sd=>Math.floor(100*avoidNaN((sd.flashcardsCorrect+sd.lessonQuestionsCorrect)/(sd.flashcardsComplete+sd.lessonQuestionsComplete)))));
    function addPercent(value) {
      return value+'%';
    }
    function addPercentToContext(context) {
      return addPercent(context.formattedValue);
    }
    graphs.wordCorrectness.options.scales.y.ticks.callback = addPercent;
    graphs.grammarCorrectness.options.scales.y.ticks.callback = addPercent;
    graphs.wordCorrectness.options.plugins.tooltip = {callbacks:{label:addPercentToContext}};
    graphs.grammarCorrectness.options.plugins.tooltip = {callbacks:{label:addPercentToContext}};


    var runningTotal = 0;
    allLevelInfo = structuredClone(progress.allLevelInfo);
    allLevelInfo.forEach(info=>{
      const numWords = info.numWords;
      info.numWords += runningTotal;
      runningTotal += numWords;
    });
  }
  //console.log('graph data', graphs);
  
  // special render funcs
  function renderProfTable(title, type, list) {
    return (<>
      <h3 class="mb-4 fw-light">{title}</h3>
      <table class="table table-borderless rounded-3 mb-0 w-100 bg-white">
        <thead>
          <tr>
            <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent'}}>{type}</th>
            <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent'}}># Seen</th>
            <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent'}}>Record</th>                
          </tr>
        </thead>
        <tbody>
          { list.map(item=>
          <tr>
            <td class="text-muted" style={{backgroundColor:'transparent'}}><Link to={getDictionaryPath(item.type, item.id)} class="link-secondary">{item.str}</Link></td>
            <td class="text-muted" style={{backgroundColor:'transparent'}}>{item.seen}</td>
            <td class="align-middle" style={{backgroundColor:'transparent'}}>
              <div className="progress" role="progressbar" style={{height:'12px'}}>
                <div className={`progress-bar bg-primary`} style={{width:`${item.ratio*100}%`}}></div>
              </div>                  
            </td>
          </tr>
          )}
        </tbody>
      </table>
    </>);
  }
  function renderGraph(graphData) {
    if(!graphData)
      return null;
    
    return (
    <div class="mt-4 rounded-3 bg-white p-3" style={{height:250,position:'relative'}}>
      <Chart type="line" data={graphData.data} options={graphData.options} />
    </div>
    );
  }
  function numberAndUnits(num, units, before='', after='') {
    return <>{before}<strong>{num} {units}{num!==1?'s':''}</strong>{after}</>;
  }
  function numberBox(colSize, num, title) {
    return (
    <div class={`col-md-${colSize} p-2 pe-5`}>
      <div class="text-center bg-white rounded-3 p-1 py-4">
        <div class="fs-1 fw-medium text-primary">{num}</div>
        <div class="text-muted">{title}</div>
      </div>
    </div>      
    );
  }
  function getLevelColor(index) {
    return (index<progress.levelInfo.id) ? 'success' : ((index===progress.levelInfo.id)?'black':'secondary');
  }
  function getLevelIcon(index) {
    return (index<progress.levelInfo.id) ? 'check-lg' : ((index===progress.levelInfo.id)?'arrow-right':'NONE');
  }
  function getLevelBgColor(index) {
    return (index===progress.levelInfo.id) ? 'white' : 'transparent';
  }
    
  // handle graph view changes
  function handleChange(e) {
    const {id} = e.target;
    const lcName = id.split('-')[0];
    const lcValue = id.split('-')[1];
    //console.log('handleChange', id, checked, lcName, lcValue, e);
    const setFuncs = { setVocab, setGrammar, setSentence };
    setFuncs[`set${capitalizeFirstLetter(lcName)}`](lcValue);
  }
  
  // render
  if(!progress) {
    return (<Loading />);
  }
  return (
    <>
      <SubHeader headerRef={props.headerRef}>
      </SubHeader>
    
      { /* Overview */ }
      
      <div class="mb-5">
        <h3 class="mb-3">Overview</h3>

        <div class="bg-light rounded-3 p-5 row">
          <div class="col-md-6 p-2">
            <h3 class="mb-3 fw-light">You have learned</h3>
      
            <div class="row pe-5">
              {numberBox(6, progress.totalWordsLearned, 'Words')}
              {numberBox(6, progress.totalGrammarsLearned, 'Grammars')}
            </div>
            <Link to="/journey"><button class="btn btn-outline-secondary w-50 mt-3">See what&apos;s next</button></Link>
          </div>
          <div class="col-md-6 p-2">
            <h3 class="mb-3 fw-light">Your fluency level</h3>
            
            <table class="table table-borderless mb-0">
              <tbody>
                { allLevelInfo.map((info, i)=>
                <tr class="mb-0">
                  <td class="p-0 pe-2 ps-2 rounded-start" style={{backgroundColor:getLevelBgColor(i)}}><i class={`bi bi-${getLevelIcon(i)} fs-2 text-${getLevelColor(i)}`}></i></td>
                  <td class={`py-2 px-1 align-middle fs-5 text-${getLevelColor(i)}`} style={{backgroundColor:getLevelBgColor(i)}}>{info.name}</td>
                  <td class={`py-2 px-1 pe-3 ps-3 align-middle rounded-end fs-5 text-${getLevelColor(i)}`} style={{backgroundColor:getLevelBgColor(i)}}>{info.numWords} words</td>
                </tr>
                ) }
              </tbody>
            </table>
                  
          </div>
        </div>
      
      </div>
      
      { /* Study */ }
    
      <div class="mb-5">
        <h3 class="mb-3">Study</h3>

        <div class="bg-light rounded-3 p-5 mb-4 row">
          <h3 class="mb-3 fw-light">{heroOpeningStatement}</h3>

          <div class="text-muted fs-5">In the past 30 days you have studied {numberAndUnits(studyDays,'day')} {(streakDays>1) ? <>- including the last <strong>{streakDays} days</strong> straight! -</>:<></>} with {numberAndUnits(heroLessons, 'lesson')} and {numberAndUnits(heroFlashcards, 'flashcard')} completed. {heroClosingStatement}</div>
      
          { renderGraph(graphs.streak) }
        </div>

        <div class="bg-light rounded-3 p-5 row">
          <div class="col-md-4 p-2">
            <h3 class="mb-3 fw-light">Your all-time history</h3>
          
            <div class="row">
              {numberBox(12, progress.streakData.length, 'Study days')}
            </div>     
          </div>
      
          <div class="col-md-8 p-2">
            <h3 class="mb-3 fw-light">Average daily progress</h3>
    
            <div class="row">
              {numberBox(6, progress.streakData.length ? (progress.totalWordsLearned/progress.streakData.length).toFixed(2) : 0, 'New Words / day')}
              {numberBox(6, progress.streakData.length ? (progress.totalGrammarsLearned/progress.streakData.length).toFixed(2) : 0, 'New Grammars / day')}
            </div>
          </div>
        </div>
      
      </div>

      { /* Fluency */ }
  
      <div class="mb-5">
        <h3 class="mb-3">Fluency</h3>

        <div class="bg-light rounded-3 p-5 mb-4 row">
          <div class="col row">
            <div class="col-8">
              <h3 class="fw-light">Vocabulary range</h3>
              <div class="text-muted pt-2 fs-5">To develop a rich vocabulary, use as many different words as possible each week.</div>
            </div>
      
            <div class="col-4 col-offset-8">
              <div class="btn-group float-end" role="group">
                <input type="radio" class="btn-check" name="vocab" id="vocab-days" onChange={handleChange} checked={vocab==='days'} />
                <label class="btn btn-outline-primary" for="vocab-days">Days</label>

                <input type="radio" class="btn-check" name="vocab" id="vocab-weeks" onChange={handleChange} checked={vocab==='weeks'} />
                <label class="btn btn-outline-primary" for="vocab-weeks">Weeks</label>

                <input type="radio" class="btn-check" name="vocab" id="vocab-months" onChange={handleChange} checked={vocab==='months'} />
                <label class="btn btn-outline-primary" for="vocab-months">Months</label>
              </div>
            </div>
          </div>

          { renderGraph(graphs.vocab) }
        </div>


        <div class="bg-light rounded-3 p-5 mb-4 row">
          <div class="col row">
            <div class="col-8">
              <h3 class="fw-light">Grammar range</h3>
              <div class="text-muted pt-2 fs-5">To learn to communicate more ideas, practice sentences with more complex grammar.</div>
            </div>
      
            <div class="col-4 col-offset-8">
              <div class="btn-group float-end" role="group">
                <input type="radio" class="btn-check" name="grammar" id="grammar-days" onChange={handleChange} checked={grammar==='days'} />
                <label class="btn btn-outline-primary" for="grammar-days">Days</label>

                <input type="radio" class="btn-check" name="grammar" id="grammar-weeks" onChange={handleChange} checked={grammar==='weeks'} />
                <label class="btn btn-outline-primary" for="grammar-weeks">Weeks</label>

                <input type="radio" class="btn-check" name="grammar" id="grammar-months" onChange={handleChange} checked={grammar==='months'} />
                <label class="btn btn-outline-primary" for="grammar-months">Months</label>
              </div>
            </div>
          </div>

          { renderGraph(graphs.grammar) }
        </div>

        <div class="bg-light rounded-3 p-5 mb-4 row">
          <div class="col row">
            <div class="col-8">
              <h3 class="fw-light">Sentence length</h3>
              <div class="text-muted pt-2 fs-5">The ability to use longer sentences is an indicator of fluency.</div>
            </div>
      
            <div class="col-4 col-offset-8">
              <div class="btn-group float-end" role="group">
                <input type="radio" class="btn-check" name="sentence" id="sentence-days" onChange={handleChange} checked={sentence==='days'} />
                <label class="btn btn-outline-primary" for="sentence-days">Days</label>

                <input type="radio" class="btn-check" name="sentence" id="sentence-weeks" onChange={handleChange} checked={sentence==='weeks'} />
                <label class="btn btn-outline-primary" for="sentence-weeks">Weeks</label>

                <input type="radio" class="btn-check" name="sentence" id="sentence-months" onChange={handleChange} checked={sentence==='months'} />
                <label class="btn btn-outline-primary" for="sentence-months">Months</label>
              </div>
            </div>
          </div>

          { renderGraph(graphs.sentence) }
        </div>
    
      </div>
                
      { /* Mastery */ }
  
      <div class="mb-3">
        <h3 class="mb-3">Mastery</h3>

        <div class="row">
          <div class="bg-light rounded-3 p-5 pe-4 col-md-6">
            { graphs.wordCorrectness &&
            <div class="mb-5">
              <h3 class="fw-light">Word correctness</h3>

              { renderGraph(graphs.wordCorrectness) }
            </div>
            }

            { !!progress.weakestWords.length &&
            <>
              { renderProfTable('Weakest words', 'Word', progress.weakestWords) }
              <div class="row mt-3"><div class="col-md-6 offset-md-6">
                <Link to='/dictionary/words'><button class="btn btn-outline-secondary w-100">See all words</button></Link>
              </div></div>
            </>
            }
          </div>
            
          <div class="bg-light rounded-3 p-5 ps-4 col-md-6">
            { graphs.grammarCorrectness &&
            <div class="mb-5">
              <h3 class="fw-light">Grammar correctness</h3>
              
              { renderGraph(graphs.grammarCorrectness) }
            </div>
            }

            { !!progress.weakestGrammars.length &&
            <>
              { renderProfTable('Weakest grammars', 'Grammar', progress.weakestGrammars) }
              <div class="row mt-3"><div class="col-md-6 offset-md-6">
                <Link to='/dictionary/grammars'><button class="btn btn-outline-secondary w-100">See all grammars</button></Link>
              </div></div>
            </>
            }
          </div>
            
        </div>    
      </div>

    </>
  );
}
import http from "./http-common";

class LoginService {
  login(email, pass) {
    return http('/auth').post(`/login`, {
      username:email,
      password:pass,
    });
  }
  signup(email, pass, fname, timezone) {
    return http('/auth').post(`/signup`, {
      username:email,
      password:pass,
      fname,
      timezone,
    });
  }
  logout(email, pass) {
    return http('/auth').post(`/logout`);
  }
}

const loginService = new LoginService();
export default loginService;
import { assert } from '../common/utility';
import { useLocalStorage } from "@uidotdev/usehooks";

const rateMap = {
  1:0.1,
  2:0.3,
  3:0.6,
  4:1,
  5:1.3,
};
const volumeMap = {
  low:0.5,
  high:1,
};
const synth = window.speechSynthesis;
synth.cancel(); // HACK - fixes (internal?) bug where sometimes it decides to stop doing synth

export default function useVoice() {
  const [ volume, setVolume ] = useLocalStorage("audioVolume", 'low'); // mute, low, high
  const [ rate, setRate ] = useLocalStorage("audioRate", 1); // 1-5

  return {
    incVolume: ()=>{
      var newVolume;
      switch(volume) {
        case 'mute':
          newVolume = 'low';
          break;
        case 'low':
          newVolume = 'high';
          break;
        case 'high':
          newVolume = 'mute';
          break;
        default:
          assert(false);
      }
      setVolume(newVolume);
    },
    getVolume: ()=>{
      return volume;
    },
    setRate: (newRate)=>{
      const options = [1,2,3,4,5];
      assert(options.includes(newRate));
      setRate(newRate);
    },
    speak: (text, lang='chinese')=>{
      if(volume==='mute')
        return;
      const utterance = new SpeechSynthesisUtterance(text.toLowerCase());
      // slow but good
      const chineseVoice = synth.getVoices().find(v=>v.voiceURI.includes('中国'));
      const englishVoice = synth.getVoices().find(v=>v.voiceURI.includes('Google US English'));
      utterance.rate = (lang==='chinese') ? 0.6 : 0.6;
      // fast but shit
      //const chineseVoice = synth.getVoices().find(v=>v.voiceURI.includes('Ting-Ting'));
      //const englishVoice = synth.getVoices().find(v=>v.voiceURI.includes('Samantha'));
      //utterance.rate = (lang==='chinese') ? 0.1 : 0.3;
      //utterance.rate = rateMap[rate];
      utterance.volume = volumeMap[volume];
      const voice = (lang==='chinese') ? chineseVoice : englishVoice;
      utterance.voice = voice;
      synth.speak(utterance);
    },
  };
}
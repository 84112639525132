import { Link } from "react-router-dom";

import { disabledLinkStyles, capitalizeFirstLetter, formatEnglish, getDictionaryPath } from '../common/utility';

import useTimer from "../hooks/timer.hook";
import useVoice from "../hooks/voice.hook";

import ChinesePhrase from "../components/chineseWord.component";

export default function WordCard({ user, word, colSize, linkDisabled, fontSize, whiteBox, includeChinese }) {
  //const [ timerCancelled, setTimerCancelled ] = useState(false);
  var timerCancelled = false;
  function setTimerCancelled(val) {
    timerCancelled = val;
  }
  const timer = useTimer();
  const voice = useVoice();
  
  //console.log('render wordCard for', word.id, timerCancelled);
  
  colSize ??= 4;
  linkDisabled ??= false;
  fontSize ??= 2;
  whiteBox ??= true;
  includeChinese ??= true;
  const emojiMargin = fontSize>=3 ? 2 : 3; // smaller margin for smaller font
  const paddingX = fontSize>=3 ? 3 : 4; // less padding for smaller font
  const english = formatEnglish(word.english, user, word.pos, word.isProper);

  function getTimerCancelled() {
    return timerCancelled;
  }
  function mouseEnter() {
    return; // HACK - disable this for now
    //console.log('mouseEnter');
    // eslint-disable-next-line
    setTimerCancelled(false);
    
    timer.fn(() => {
      //console.log('play word?', word.id, timerCancelled, getTimerCancelled());
      if(!getTimerCancelled()) { // need to call func instead of using var otherwise it will cache value when timer is created
        //console.log('play word', word);
        voice.speak(word.characters);
      }
    }, 250);
  }
  function mouseLeave() {
    //console.log('mouseLeave');
    setTimerCancelled(true);
  }
  function mouseClick(e) {
    if(linkDisabled)
      e.preventDefault();
  }

  return (
  <div class={`col-${colSize} p-2`}>
    <Link to={getDictionaryPath('word', word.id)} style={!linkDisabled?{textDecoration:'inherit', color:'inherit'}:{...disabledLinkStyles, pointerEvents:'auto', cursor:'default'}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={mouseClick}>
    <div class={`${whiteBox?'bg-white rounded-3':''} fs-${fontSize} px-${paddingX} py-2`}>
      { word.emoji &&
      <span class={`me-${emojiMargin}`}>{word.emoji}</span>
      }
      { includeChinese ?
      <>
        <span class="text-black"><ChinesePhrase words={word.chinese} /></span>
        <span class="text-muted"> → {english}</span>
      </>
      :
      <span>{capitalizeFirstLetter(english)}</span>
      }
    </div>
    </Link>
  </div>
  );
}
import { useEffect, useRef } from "react";

export default function SubHeader({children, headerRef, subheaderRef, backgroundColor}) {
  const myRef = useRef();
  if(!subheaderRef)
    subheaderRef = myRef;
  
  const headerHeight = headerRef?.current?.clientHeight;  

  useEffect(() => {
    if(subheaderRef?.current) {
      function getCoords(el) {
        if(!el)
          return null;
        return el.getBoundingClientRect();
      }
      const subheaderLeft = getCoords(subheaderRef.current).left;
      const statBoxLeft = getCoords(headerRef?.current?.querySelector('#statBox'))?.left;
      subheaderRef.current.style.width=`${statBoxLeft-subheaderLeft}px`;
    }
  });
  
  return (
    <div class={`py-4 pe-4 sticky-top ${!!children?'bg-white':''}`} style={{top:headerHeight, backgroundColor, zIndex:2000}} ref={subheaderRef}>
      {children}
    </div>
  );
}
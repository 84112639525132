import { Link, useParams, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";

import useData from "../hooks/data.hook";

import LessonCard from "./lessonCard.component";
import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import { assert, capitalizeFirstLetter, formatEnglish, formatChinese } from '../common/utility';

export default function Dictionary({headerRef}) {  
  const navigate = useNavigate();
  const { user } = useData('user');
  const { dictionary } = useData('dictionary');  
  const [ mode, setMode ] = useLocalStorage("dictMode", 'words');
  const defaultSort = 'english';
  const [ sortBy, setSortBy ] = useLocalStorage("dictSortBy", defaultSort);
  const [ reverseSort, setReverseSort ] = useLocalStorage("dictReverseSort", false);
  const [ showFilters, setShowFilters ] = useLocalStorage("dictShowFilters", false);
  const [ groupBy, setGroupBy ] = useLocalStorage("dictGroupBy", 'default');
  const [ searchText, setSearchText ] = useState("");
  var { modeUrl, id } = useParams();

  console.log('Dictionary - render', dictionary);

  // determine header offset / sticky top
  const [ stickyTop, setStickyTop ] = useState(null);
  const subheaderRef = useRef();
  const headerHeight = headerRef?.current?.clientHeight;
  const headerOffset = 0; //30;
  // eslint-disable-next-line
  useEffect(() => {
    const subheaderHeight = subheaderRef?.current?.clientHeight;
    const newStickyTop = headerHeight+subheaderHeight+headerOffset;
    //console.log('stickyTop', stickyTop, newStickyTop);
    if(stickyTop!==newStickyTop) // avoid infinite loop
      setStickyTop(newStickyTop);
  });

  // return if we haven't loaded data yet
  if(!user || !dictionary) {
    return (<Loading />);
  }
  const dict = dictionary;
  
  // get view mode
  if(modeUrl && mode !== modeUrl) {
    setMode(modeUrl);
    return;
  }
  assert(['words', 'grammars', 'expressions'].includes(mode), `Dictionary - Invalid mode '${mode}'`);
  var items = dict[mode]; // backend returns obj with fields matching modes

  // ensure valid filters
  var setAny = false;
  switch(mode) {
  case 'grammars':
    if(sortBy==='chinese' || sortBy==='english') {
      setSortBy('name');
      setAny = true;
    }
    if(groupBy==='topic') {
      setGroupBy('default');
      setAny = true;
    }
    break;
  case 'words':
    if(sortBy==='name') {
      setSortBy(defaultSort);
      setAny = true;    
    }
    break;
  case 'expressions':
    if(!(sortBy==='chinese' || sortBy==='english')) {
      setSortBy(defaultSort);
      setAny = true;
    }
    break;
  default:
    assert(false);
  }
  if(setAny)
    return;
  
  // duplicate words if listing by definition
  if(mode==='words' && sortBy==='english') {
    const newItems = [];
    items.forEach(item=>{
      if(item.defs.length>1) {
        item.defs.slice(1).forEach((def, i)=>{ // we already have index 0 definition in the items list
          const newItem = structuredClone(item);
          newItem.defs = [def].concat(item.defs.filter(d=>d!==def));
          newItem.groups.english = formatEnglish(def.toLowerCase()[0], user);
          newItem.firstPOS = item.defPOSs[i+1];
          newItems.push(newItem);
        });
      }
    });
    items = items.concat(newItems);
  }
  
  // handle group by
  const noneGroupName = '(None)';
  var getGroupName;
  switch(groupBy) {
  case 'default':
    items.forEach(item=>{
      if(sortBy==='learned') {
        const numDays = item.groups[sortBy];
        if(numDays<7)
          item.groupKey = `days${numDays}`;
        else
          item.groupKey = `weeks${Math.floor(numDays/7)}`;
      }
      else {
        item.groupKey = item.groups[sortBy]; // group attrs are named after sort levels
      }
    }); 
    getGroupName = (key) => {
      switch(sortBy) {
      case 'chinese':
      case 'english':
      case 'name':
        return key.toUpperCase();
      case 'mastery':
        return `${key}%`;
      case 'learned':
        if(key==='days0')
          return 'Today';
        else if(key==='days1')
          return 'Yesterday';
        else if(key[0]==='d')
          return `${key.substring(4)} days ago`;
        else if(key==='weeks1')
          return 'Last week';
        else
          return `${key.substring(5)} weeks ago`;
      default:
        assert(false);
      }
    };
    break;
  case 'topic':
    items.forEach(item=>item.groupKey=item.topic?item.topic:noneGroupName);
    getGroupName = (topic) => topic ? topic : noneGroupName;
    break;
  case 'level':
    items.forEach(item=>item.groupKey=item.introLevel+1);
    getGroupName = (level) => `Level ${level}`;
    break;
  default:
    assert(false);
  }
  function compareFunc(a, b) {
    if(a<b)
      return -1;
    else if(a===b)
      return 0;
    else
      return 1;
  }
  items.sort((a,b)=>compareFunc(a.groupKey, b.groupKey));
  const groups = [];
  var lastGroupKey, groupItems = [];
  function finishGroup() {
    assert(groupItems.length, `group ${lastGroupKey} has no items`);
    groups.push({
      name:getGroupName(lastGroupKey),
      items:groupItems,
    });
  }
  function getItemName(item) {
    switch(sortBy) {
    case 'mastery':
    case 'learned':
    case 'chinese':
    case 'name':
      switch(mode) {
      case 'expressions':
      case 'words':
        return formatChinese(item, user); // items are not words but they have similar enough props
      case 'grammars':
        return item.name;
      default:
        assert(false);
        return null;
      }
      // break
    case 'english':
      return item.english ? item.english : item.defs[0];
    default:
      assert(false);
    }
  }
  function addItem(item, name) {
    var id;
    switch(mode) {
    case 'words':
      id = item.wordId;
      break;
    case 'expressions':
    case 'grammars':
      id = item.id;
      break;
    default:
      assert(false);
    }
    assert(id!==undefined);
    groupItems.push({
      name,
      link:`/dictionary/${mode}/${id}`,
      item,
    })
  }
  items.forEach(item=>{
    const itemName = getItemName(item);
    if(!!searchText && !itemName.replaceAll('·','').toLowerCase().includes(searchText.toLowerCase()))
      return; // we are searching and this item's name doesn't match
    if(lastGroupKey!==undefined && item.groupKey!==lastGroupKey) {
      finishGroup();
      groupItems = [];
    }
    addItem(item, itemName);
    lastGroupKey = item.groupKey;
  });
  if(items.length) {
    if(lastGroupKey)
      finishGroup();
    else
      assert(!!searchText, `invalid lastGroupKey - we aren't searching so we should have one`);
  }
  
  // handle sort by
  switch(sortBy) {
  case 'chinese':
  case 'english':
  case 'name':
    items.forEach(item=>item.sortKey=getItemName(item).toLowerCase());
    break;
  case 'mastery':
  case 'learned':
    items.forEach(item=>item.sortKey=item.sorts[sortBy]);
    break;
  default:
    assert(false);
  }
  groups.forEach(group=>{
    group.items.sort((a,b)=>compareFunc(a.item.sortKey, b.item.sortKey));
    if(reverseSort)
      group.items.reverse();
  });
  if(groups.length && groups[0].name===noneGroupName)
    groups.push(groups.shift()); // move first element to end
  if(reverseSort)
    groups.reverse();
  
  // get lesson card
  var lcType, lcItem;
  switch(mode) {
  case 'words':
    lcType = 'word';
    if(!!id)
      lcItem = items.find(item=>item.wordId===id);
    break;
  case 'grammars':
    lcType = 'grammar';
    if(!!id)
      lcItem = items.find(item=>item.id===id);
    break;
  case 'expressions':
    lcType = 'expression';
    if(!!id)
      lcItem = items.find(item=>item.id===id);
    break;
  default:
    assert(false);
  }
  if(!id && groups.length) {
    lcItem = groups[0].items[0].item;
  }
  
  // filters
  function handleChange(e) {
    const {id, checked} = e.target;
    var lcName = id.split('-')[0];
    const lcValue = id.split('-')[1];
    //console.log('handleChange', id, checked, lcName, lcValue, e)
    if(lcName==='mode' || lcName==='mode2') // need to call it 'mode2' because we can't have 2 inputs with the same name
      navigate(`/dictionary/${lcValue}`);
    else if(lcName==='reverseSort')
      setReverseSort(checked);
    else if(lcName==='showFilters') {
      setShowFilters(checked);
    }
    else {
      const setFuncs = { setSortBy, setReverseSort, setShowFilters, setGroupBy };
      setFuncs[`set${capitalizeFirstLetter(lcName)}`](lcValue);
    }
  }
  
  // search
  function handleSearchChange(e) {
    const text = e.target.value;
    setSearchText(text);
  }
  function handleSearchClear() {
    setSearchText('');
  }
    
  // render
  console.log(lcType, lcItem, mode, groupBy, sortBy, reverseSort);
  return (<>
    <SubHeader headerRef={headerRef} subheaderRef={subheaderRef} backgroundColor="white">
      <div class="row">
        <div class="col">
          <div class="d-inline-block w-50">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search..." onChange={handleSearchChange} value={searchText} />
              { !!searchText &&
              <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleSearchClear}>
                <i class="bi bi-x btn-icon"></i>
              </button>
              }
            </div>
          </div>
          <div class="ms-4 d-inline btn-group align-top" role="group">
            <input type="radio" class="btn-check" name="mode" id="mode-words" onChange={handleChange} checked={mode==='words'} />
            <label class="btn btn-outline-primary" for="mode-words">Words</label>
            { !!dict.grammars.length &&
            <>
              <input type="radio" class="btn-check" name="mode" id="mode-grammars" onChange={handleChange} checked={mode==='grammars'} />
              <label class="btn btn-outline-primary" for="mode-grammars">Grammars</label>
            </>
            }
          </div>
          <div class="ms-4 d-inline align-top">
            <input type="checkbox" class="btn-check" id="showFilters" onChange={handleChange} checked={!!showFilters} />
            <label class="btn btn-outline-primary" for="showFilters"><i class="bi bi-filter-right btn-icon"></i></label>
          </div>
        </div>
      </div>
      <div class={`pt-4 row collapse ${!!showFilters?'show':''}`} id="filterBox">
        <div class="col-2 me-4">
          <p class="text-muted fw-semibold mb-2">Show</p>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="mode2" id="mode2-words" onChange={handleChange} checked={mode==='words'} />
            <label class="form-check-label" for="mode2-words">Words</label>
          </div>
          { !!dict.grammars.length &&
          <div class="form-check">
            <input class="form-check-input" type="radio" name="mode2" id="mode2-grammars" onChange={handleChange} checked={mode==='grammars'} />
            <label class="form-check-label" for="mode2-grammars">Grammars</label>
          </div>
          }
          { !!dict.grammars.length &&
          <div class="form-check">
            <input class="form-check-input" type="radio" name="mode2" id="mode2-expressions" onChange={handleChange} checked={mode==='expressions'} />
            <label class="form-check-label" for="mode2-expressions">Expressions</label>
          </div>
          }
        </div>
        <div class="col-2">
          <p class="text-muted fw-semibold mb-2">Group by</p>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="groupBy" id="groupBy-default" onChange={handleChange} checked={groupBy==='default'} />
            <label class="form-check-label" for="groupBy-default">Default</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="groupBy" id="groupBy-level" onChange={handleChange} checked={groupBy==='level'} />
            <label class="form-check-label" for="groupBy-level">Level</label>
          </div>
          { (mode==='words' || mode==='expressions') &&
          <div class="form-check">
            <input class="form-check-input" type="radio" name="groupBy" id="groupBy-topic" onChange={handleChange} checked={groupBy==='topic'} />
            <label class="form-check-label" for="groupBy-topic">Topic</label>
          </div>
          }
        </div>
        <div class="col-2">
          <p class="text-muted fw-semibold mb-2">Sort by</p>
          { mode==='words' || mode==='expressions' ?
          <>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="sortBy" id="sortBy-chinese" onChange={handleChange} checked={sortBy==='chinese'} />
            <label class="form-check-label" for="sortBy-chinese">Chinese</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="sortBy" id="sortBy-english" onChange={handleChange} checked={sortBy==='english'} />
            <label class="form-check-label" for="sortBy-english">English</label>
          </div>
          </>
          :
          <div class="form-check">
            <input class="form-check-input" type="radio" name="sortBy" id="sortBy-name" onChange={handleChange} checked={sortBy==='name'} />
            <label class="form-check-label" for="sortBy-name">Name</label>
          </div>
          }
          { mode!=='expressions' && 
          <>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="sortBy" id="sortBy-mastery" onChange={handleChange} checked={sortBy==='mastery'} />
            <label class="form-check-label" for="sortBy-mastery">Mastery</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="sortBy" id="sortBy-learned" onChange={handleChange} checked={sortBy==='learned'} />
            <label class="form-check-label" for="sortBy-learned">Learned</label>
          </div>
          </>
          }
        </div>
        <div class="col-3">
          <p class="text-muted fw-semibold mb-2">Sort direction</p>
          <div class="">
            <input type="checkbox" class="btn-check" id="reverseSort" onChange={handleChange} checked={!!reverseSort} />
            <label class="btn btn-outline-primary" for="reverseSort"><i class="bi bi-sort-alpha-down-alt btn-icon"></i></label>
          </div>
        </div>
      </div>
    </SubHeader>
    { !groups.length ?
    <div class="p-5 bg-light rounded-3 fs-3 text-center">
      <span>Sorry, nothing matches your search!</span>
    </div>
    :
    <div class="row">
      <div class="col-3">
        { groups.map((group, i)=>
        <div class="mb-3" key={i}>
          <p class="text-muted fw-semibold">{group.name}</p>
          <div class="list-group">
            { group.items.map((item, j)=>
            <Link to={item.link} class={`list-group-item list-group-item-action ${(mode==='words'?lcItem.wordId===item.item.wordId:lcItem.id===item.item.id)?'active':''}`} key={j}>
              {(sortBy==='english' && mode!=='expressions') ? formatEnglish(item.name, user, item.item.firstPOS, item.item.isProper) : item.name}
            </Link>
            )}
          </div>
        </div>
        )}
      </div>
      <div class="col-9">
        <div class="p-5 bg-light rounded-3 sticky-top" style={{top:stickyTop}}>
          { !!lcItem &&
          <LessonCard type={lcType} itemData={lcItem} usedWordData={dict.usedWordData} user={user} fullView={true} />
          }
          { !lcItem && !!id &&
          <span>Sorry, this word is not in your dictionary!</span>
          }
        </div>
      </div>
    </div>    
    }
  </>);
}
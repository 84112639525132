import LoginService from "../services/login.service";
import { useLocalStorage } from "@uidotdev/usehooks";

import { resetAllData } from "./data.hook";

export default function useAuth() {
  const [token, setToken] = useLocalStorage('token', null);

  function getToken() {
    if(token==='null')
      return null;
    return token;
  }

  return {
    authed:!!getToken(),
    userId:getToken(),
    login(email, pass) {
      return new Promise((res) => {
        LoginService.login(email, pass)
          .then(response => {
            console.log('login result', response);
            if(response.data===false) {
              setToken(null);
              res(false);
            }
            else {
              const user = response.data;
              console.log('successful login', user);
              setToken(user.id);
              res(user);
            }
          })
          .catch(e => {
            console.log(e);
            res(false);
          });
      });
    },
    signup(email, pass, fname, timezone) {
      return new Promise((res) => {
        LoginService.signup(email, pass, fname, timezone)
          .then(response => {
            const user = response.data;
            console.log('successful signup', user);
            setToken(user.id);
            res(user);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
    logout() {
      return new Promise((res) => {
        LoginService.logout()
          .then(response => {
            console.log('successful logout');
            setToken(null);
            window.localStorage.clear();
            resetAllData();
            res(true);
          })
          .catch(e => {
            console.log(e);
          });
      });
    },
  };
}

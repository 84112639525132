import ENV from "../common/config.env";
import axios from "axios";

export default function http(baseAPIUrl = '/api') {
  const baseUrl = (ENV==='dev') ? 'http://localhost:2000' : 'https://easychinese.app';
  return axios.create({
    baseURL: baseUrl+baseAPIUrl,
    headers: {
      "Content-type": "application/json"
    }
  });
}
import http from "./http-common";

function getAPIPath(path) {
  return `/user`+(path?`/${path}`:'');
}
async function userAPIGet(path) {
  var out = await http().get(getAPIPath(path));
  console.log(`userAPIGet ${getAPIPath(path)}`, out);
  out = out.data;
  return out;
}
async function userAPIPut(path, data) {
  var out = await http().put(`/user/${path}`, data);
  out = out.data;
  return out;
}

class UserDataService {
  async getUser() {
    return await userAPIGet();
  }
  async getFlashcards(wordOnly) {
    return await userAPIGet(`flashcards/${wordOnly?'wordOnly':''}`);
  }
  async getLesson() {
    return await userAPIGet(`lesson`);
  }
  async finishLesson(data) {
    return await userAPIPut(`finishLesson`, data);
  }
  async getProgress() {
    return await userAPIGet(`progress`);
  }
  async getMap() {
    return await userAPIGet(`map`);
  }
  async getDictionary() {
    return await userAPIGet(`dictionary`);
  }
  async getJourney() {
    return await userAPIGet(`journey`);
  }
}

const userDataService = new UserDataService();
export default userDataService;
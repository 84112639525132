import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function About() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }); // run ever render

  return (
    <div class="mt-4">
      <div class="p-3 pb-md-4 mx-auto text-center">
        <h1 class="display-4 fw-normal mb-3">Our Method</h1>
      </div>
      <div class="fs-5 text-muted lh-lg">
        <p>Traditional Chinese education takes years because students are taught the same way that Chinese children learn in grade school. English-speaking adults are less plastic, have less time, and are wired to speak English, so a radically different approach is required. Apps like Duolingo will make you memorize Chinese characters and teach you 10 different names of animals before you can order a coffee. We don't believe there is an effective method to quickly teach adults Chinese which is why we built Easy Chinese.</p>
        <p>Chinese is different from English in 2 ways that can make it hard to learn:</p>
        <ol>
          <li>Chinese is a "tonal" language, meaning that small adjustments in how you pronounce a word can change its meaning</li>
          <li>Chinese is a "pictographic" language, meaning that it does not use an alphabet like every other living language. Chinese uses "pictograms" which you can think of as representing meanings or as a very large alphabet. There are over 100,000 characters although your average book or newspaper uses less than 5,000</li>
        </ol>
        <p>Learning a tonal, pictographic language is incredibly challenging for the English-speaking mind. We are programmed to think of words as strings of letters, not as pictures or sounds, which can take 10x longer to learn. Due to this alien nature, many believe Chinese to be the hardest language. <strong>This is wrong, in fact it is the easiest...</strong> if you are willing to cut some corners. Luckily, we can:</p>
        <ol>
          <li>While tones are important, you can learn hundreds of words without tones before you run into ambiguity. This simple vocabulary can be understood by native Chinese speakers via context clues. Over time, you will pick up the correct tones as you would learn an accent</li>
          <li>Chinese characters are beautiful, and while it's fun to learn them, for writing on a device you can use "Pinyin" which is a popular Romanization of Chinese that can be entered using a standard QWERTY keyboard. Most native speakers write in Chinese this way and some even forget how to write characters by hand. For reading characters on a device you can use translation software. If you need to read characters IRL you can learn them in the later stages of this app or using flashcard software</li>
          <li>Thankfully, Chinese grammar is similar to English, and it is not a conjugated language, meaning you don't have to memorize 1000's of special word endings as you would in a language like Spanish</li>
        </ol>
        <p>Given this, our method is simple: start with Toneless Pinyin which reduces Chinese words to strings of English letters. For example, the word "hello" is written "ni hao". As your vocabulary grows, introduce tones where necessary to disambiguate between words with the same Toneless representation. For example, "he1" means "to drink" and "he2" means "and". When you're ready, introduce Chinese characters and you can learn as many as you'd like!</p>
        <p>Our goal is to get you speaking and understanding Chinese from day 1 to build your motivation to keep learning and ultimately master the language.</p>
        <p>We hope you'll join our many happy learners and give us a try!</p>
      </div>
      <Link to="/signup">
        <button className="btn btn-primary btn-lg mt-3 mb-5 signup-btn">Start now <i class="bi bi-chevron-right ms-1 fs-6"></i></button>
      </Link>
    </div>
  );
}